import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ContainerProps {
  clear?: boolean;
}

export const Container = styled.button<ContainerProps>`
  width: 100%;
  height: 50px;
  background: #3784f6;
  padding: 0 30px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  border: solid 2px #3784f6;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#3784f6')};
    border-color: ${shade(0.2, '#3784f6')};
  }

  ${props =>
    props.clear &&
    css`
      && {
        border: none;
        background: none;
        color: #3784f6;
        height: 25px;

        &:hover {
          background: none;
          border: none;
        }
      }
    `}
`;
