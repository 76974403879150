import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 20px 0;
`;

export const Content = styled.div`
  flex: 1;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #959595;
  }
`;

export const Table = styled.div`
  background: #fff;
  border-radius: 18px;
  border: 3px #fff solid;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
  &:hover {
    border: 3px #4786fa solid;
  }
`;

export const Li = styled.li`
  transform: translateX(-15px);
`;

export const TableContent = styled.div`
  width: 100%;
  height: 400px;
  background: #fff;
  padding-top: 10px;
  overflow: auto;
  transition: 0.2s;
  border-radius: 18px;

  &::-webkit-scrollbar {
    display: auto;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: #3784f6;
`;
export const Payment = styled.div`
  padding-top: 20px;
  display: flex;

  h2 {
    font-size: 13px;
    color: #707070;
    font-weight: 500;
  }

  h1 {
    padding-left: 5px;
    font-size: 13px;
    color: #3784f6;
    font-weight: 600;
  }
`;

export const DetailCard = styled.div`
  flex: 1;
  display: flex;
  margin-top: 20px;
  width: 100%;
  background: #fff;
  border-radius: 18px;
  padding: 20px;
  transition: 0.2s;
  border: 3px #fff solid;

  &:hover {
    border: 3px #4786fa solid;
  }
`;

export const LeftDetail = styled.div`
  flex: 1;
`;

export const InfoDetail = styled.div`
  h3 {
    color: #707070;
    font-size: 13px;
    font-weight: 500;
  }

  h1 {
    margin-top: 10px;
    color: #161940;
    font-size: 16px;
    font-weight: 600;
    background: #f1f1f1;
    padding: 15px 35px;
    border-radius: 8px;
  }

  & + div {
    margin-left: 30px;
  }
`;

export const NoteDetail = styled.div`
  margin-top: 20px;

  h3 {
    color: #707070;
    font-size: 13px;
    font-weight: 500;
  }

  h1 {
    margin-top: 10px;
    color: #161940;
    font-size: 14px;
    font-weight: 500;
    background: #f1f1f1;
    padding: 15px 35px;
    min-height: 100px;
    border-radius: 8px;
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const RightDetail = styled.div`
  display: block;
  flex: 1;
  max-width: 300px;
  margin-left: 50px;
  justify-content: space-around;
`;

export const PriceDetail = styled.div`
  padding: 0 0 80px;

  h3 {
    color: #707070;
    font-size: 13px;
    font-weight: 500;
  }

  h1 {
    margin-top: 10px;
    color: #161940;
    font-size: 26px;
    font-weight: 600;
    background: #f1f1f1;
    padding: 15px 35px;
    border-radius: 8px;

    span {
      font-weight: 400;
    }
  }
`;

export const AcceptButton = styled.button`
  width: 100%;
  border: none;
  background: #3784f6;
  color: #fff;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 19px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#3784f6')};
  }
`;
