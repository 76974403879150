import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  a {
    display: flex;
    text-decoration: none;
    width: 100%;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: 100ms;

    &:hover {
      border-color: #3784f6;
    }

    li {
      flex: 1;
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #000;
    }
  }
`;
