import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
  memo,
} from 'react';
import { FaCamera } from 'react-icons/fa';

import { useField } from '@unform/core';

import { Container } from './styles';

interface Props {
  name: string;
  defaultPreview: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const InputFile: React.FC<InputProps> = ({
  name,
  onChange,
  defaultPreview,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue = defaultPreview } = useField(
    name,
  );

  const [preview, setPreview] = useState(defaultValue);

  const handlePreview = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) setPreview(defaultValue);
      else {
        const previewURL = URL.createObjectURL(file);
        setPreview(previewURL);
      }

      if (onChange) onChange(e);
    },
    [defaultValue, onChange],
  );

  useEffect(() => {
    if (typeof defaultValue === 'object') {
      const previewURL = URL.createObjectURL(defaultValue);
      setPreview(previewURL);
    }
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      clearValue() {
        if (inputRef.current) inputRef.current.value = '';

        setPreview(null);
      },
      getValue() {
        if (typeof defaultValue === 'object') return defaultValue;

        if (inputRef.current && inputRef.current.files)
          return inputRef.current.files[0];

        return undefined;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container>
      {preview && <img src={preview} alt="Preview" />}
      <label htmlFor={fieldName}>
        <FaCamera size={20} color="#fff" />
        <input
          id={fieldName}
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
        />
      </label>
    </Container>
  );
};

export default memo(InputFile);
