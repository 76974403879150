import React, { useState } from 'react';

import { HeaderTitle, BackButton } from '../../components';
import Companies from './Companies';
import MenuItem from './MenuItem';
import Payment from './Payment';
import Profile from './Profile';
import { Container, Header, Menu, Content } from './styles';
import Users from './Users';

const Account: React.FC = () => {
  const [step, setStep] = useState('profile');

  return (
    <Container>
      <Header>
        <HeaderTitle title="Conta" />
        <BackButton />
      </Header>

      <Menu>
        <MenuItem
          title="Meu Perfil"
          isActive={step === 'profile'}
          onClick={() => setStep('profile')}
        />
        <MenuItem
          title="Empresas"
          isActive={step === 'companies'}
          onClick={() => setStep('companies')}
        />
        <MenuItem
          title="Usuários"
          isActive={step === 'users'}
          onClick={() => setStep('users')}
        />
        <MenuItem
          title="Pagamento"
          isActive={step === 'payment'}
          onClick={() => setStep('payment')}
        />
      </Menu>
      <Content>
        {step === 'profile' ? (
          <Profile />
        ) : step === 'companies' ? (
          <Companies />
        ) : step === 'users' ? (
          <Users />
        ) : (
          step === 'payment' && <Payment />
        )}
      </Content>
    </Container>
  );
};

export default Account;
