import { QuoteStatus } from '../@types/quote';

export function formatStatus(status: QuoteStatus): string {
  switch (status) {
    case 'opened':
      return 'Aberto';
    case 'in analysis':
      return 'Em Análise';
    case 'distribution':
      return 'Em Distribuição';
    case 'finished':
      return 'Finalizado';
    case 'canceled':
      return 'Cancelado';
    default:
      return status;
  }
}
