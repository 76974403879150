import React, { useCallback, useMemo, useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Provider } from '../../../@types/provider';
import api from '../../../services/api';
import { formatCnpj } from '../../../utils/cnpj';
import LastProductsProvider from './LastProductsProvider';
import ProviderCategories from './ProviderCategories';
import ProviderInfo from './ProviderInfo';
import { Container, Content, ContentRow, Header } from './styles';

interface ProviderItemProps {
  provider: Provider;
  onDelete: (provider: Provider) => void;
}

const ProviderItem: React.FC<ProviderItemProps> = ({ provider, onDelete }) => {
  const [expanded, setExpanded] = useState(false);
  const [, setLoading] = useState(false);

  const handleToggleExpanded = useCallback(() => {
    setExpanded(state => !state);
  }, []);

  const handleDeleteProvider = useCallback(async () => {
    try {
      setLoading(true);

      await api.delete(`providers/${provider.id}`);

      onDelete(provider);
    } catch (error) {
      const { response } = error;

      if (!response) {
        toast('Ocorreu um erro desconhecido', { type: 'error' });
        return;
      }

      switch (response.status) {
        case 401:
          toast('Você não está autenticado, por favor faça login novamente.', {
            type: 'error',
          });
          break;
        case 403:
          toast('Você não está autorizado.', { type: 'warning' });
          break;
        default:
          toast('Ocorreu uma falha na página, por favor recarregue!', {
            type: 'error',
          });
      }
    } finally {
      setLoading(false);
    }
  }, [onDelete, provider]);

  const cnpjFormated = useMemo(() => {
    if (provider) return formatCnpj(provider.cnpj);

    return '';
  }, [provider]);

  return (
    <Container isExpanded={expanded}>
      <Header
        type="button"
        onClick={handleToggleExpanded}
        isExpanded={expanded}
      >
        {expanded && (
          <div>
            <h2>{provider.name}</h2>
            <FaChevronUp size={22} color="#3784F6" />
          </div>
        )}

        {!expanded && (
          <ul>
            <li>{provider.name}</li>
            <li>{cnpjFormated}</li>
            <li>
              {provider.email}
              <FaChevronDown size={22} />
            </li>
          </ul>
        )}
      </Header>
      {expanded && (
        <Content>
          <ProviderInfo provider={provider} onDelete={handleDeleteProvider} />

          <ContentRow>
            <ProviderCategories
              provider={provider}
              categories={provider.categories}
            />
            <LastProductsProvider lastProducts={provider.lastProducts || []} />
          </ContentRow>
        </Content>
      )}
    </Container>
  );
};

export default ProviderItem;
