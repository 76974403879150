import React, { useRef, useEffect, CSSProperties, memo } from 'react';
import { OptionTypeBase } from 'react-select';
import { Props as AsyncCreatableProps } from 'react-select/async-creatable';

import { useField } from '@unform/core';

import { Container, Title, SelectContent } from './styles';

interface SelectProps extends AsyncCreatableProps<OptionTypeBase> {
  name: string;
  title?: string;
  containerStyle?: CSSProperties;
}

const SelectAsyncCreatable: React.FC<SelectProps> = ({
  name,
  getOptionValue,
  title,
  containerStyle,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      clearValue: ref => {
        ref.select.state.value = null;
      },
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }

        if (!ref.select.state.value) return '';

        if (getOptionValue) return getOptionValue(ref.select.state.value);

        return ref.select.state.value;
      },
    });
  }, [fieldName, getOptionValue, registerField, rest.isMulti]);

  return (
    <Container style={containerStyle}>
      <Title>{title}</Title>
      <SelectContent
        ref={selectRef}
        defaultValue={defaultValue}
        getOptionValue={getOptionValue}
        classNamePrefix="react-select"
        placeholder="Selecione"
        {...rest}
      />
    </Container>
  );
};

export default memo(SelectAsyncCreatable);
