import React, { useState, useEffect, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { ProductQuote } from '../../@types/product';
import { Quote, QuoteParams } from '../../@types/quote';
import { HeaderTitle, BackButton, Spinner } from '../../components';
import { useCompany } from '../../hooks/company';
import api from '../../services/api';
import { formatAddress } from '../../utils/address';
import { formatStatus } from '../../utils/quote';
import NoProductSelected from './NoProductSelected';
import ProductQuoteItem from './ProductQuoteItem';
import ProductsProviders from './ProductsProviders';
import {
  Container,
  Header,
  TopContent,
  TableContent,
  Status,
  Table,
  DescriptionHeader,
  TableProps,
} from './styles';

const QuoteDetail: React.FC = () => {
  const { params } = useRouteMatch<QuoteParams>();

  const [quote, setQuote] = useState<Quote>();
  const [loading, setLoading] = useState(true);
  const [productSelected, setProductSelected] = useState<ProductQuote>();

  const { currentCompany } = useCompany();

  useEffect(() => {
    async function loadDetail() {
      try {
        setLoading(true);

        const response = await api.get(`quotes/${params.quoteid}`);

        const { data } = response;

        setQuote({ ...data, status: formatStatus(data.status) });
      } catch (error) {
        const { response } = error;

        if (response)
          switch (response.status) {
            case 401:
              toast(
                'Você não está autenticado, por favor faça login novamente.',
                { type: 'error' },
              );
              break;
            case 403:
              toast('Você não está autorizado.', { type: 'warning' });
              break;
            default:
              toast('Falha na página, por favor recarregue!', {
                type: 'error',
              });
          }
      } finally {
        setLoading(false);
      }
    }

    if (currentCompany) loadDetail();
  }, [currentCompany, params]);

  const addressFormated = useMemo(() => {
    if (quote)
      return formatAddress(quote.deliveryAddress, {
        complement: true,
        zipcode: true,
        formatZipcode: true,
      });

    return '';
  }, [quote]);

  return (
    <Container>
      {/* TODO add spinner */}
      {loading && (
        <div>
          <Spinner />
        </div>
      )}
      {!loading && quote && (
        <>
          <Header>
            <div>
              <HeaderTitle title={quote.title} />
              {quote.description && <p>{quote.description}</p>}
            </div>
            <BackButton />
          </Header>

          <DescriptionHeader>
            <h1>{addressFormated}</h1>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <Status statusCode={quote.status}>
                <p>{quote.status}</p>
              </Status>
              <h2>
                {format(parseISO(quote.createdAt), "dd 'de' MMMM 'de' yyyy", {
                  locale: ptLocale,
                })}
              </h2>
            </div>
          </DescriptionHeader>

          <TopContent>
            <Table>
              <ul style={{ color: '#232242' }}>
                <li style={{ fontWeight: 'bold', color: '#232242' }}>
                  Produto
                </li>
                <li style={{ fontWeight: 'bold', color: '#232242' }}>
                  Fabricante
                </li>
                <li style={{ fontWeight: 'bold', color: '#232242' }}>Modelo</li>
                <li style={{ fontWeight: 'bold', color: '#232242' }}>
                  Quantidade
                </li>
              </ul>
              <TableContent>
                {quote.products.map(product => (
                  <ProductQuoteItem
                    key={product.id}
                    productQuote={product}
                    onSelected={setProductSelected}
                  />
                ))}
              </TableContent>
            </Table>
          </TopContent>

          <TableProps>
            <Link to="/">Visualizar todas propostas</Link>
          </TableProps>

          {!productSelected && <NoProductSelected />}

          {productSelected && (
            <ProductsProviders productQuote={productSelected} />
          )}
        </>
      )}
    </Container>
  );
};

export default QuoteDetail;
