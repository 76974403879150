import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import {
  Pagination as PaginationType,
  PaginationHandler,
} from '../../../@types/pagination';
import { ProductProvider, ProductQuote } from '../../../@types/product';
import { Pagination } from '../../../components';
import api from '../../../services/api';
import CardProductProvider from '../CardProductProvider';
import { CardDescription, CardsContainer } from './styles';

interface ProductsProvidersProps {
  productQuote: ProductQuote;
}

const ProductsProviders: React.FC<ProductsProvidersProps> = ({
  productQuote,
}) => {
  const [loading, setLoading] = useState(true);

  const [productsProviders, setProductsProviders] = useState<ProductProvider[]>(
    [],
  );
  const [pagination, setPagination] = useState<PaginationType>();

  const handleFindPage = useCallback<PaginationHandler>(
    async options => {
      try {
        setLoading(true);

        const response = await api.get(
          `products-quotes/${productQuote.id}/products-providers`,
          { params: options },
        );

        const { data, pagination: paginationResponse } = response.data;
        setProductsProviders(data);
        setPagination(paginationResponse);
      } catch (error) {
        const { response } = error;

        if (!response) {
          toast('Ocorreu um erro desconhecido', { type: 'error' });
          return;
        }

        switch (response.status) {
          case 401:
            toast(
              'Você não está autenticado, por favor faça login novamente.',
              {
                type: 'error',
              },
            );
            break;
          case 403:
            toast('Você não está autorizado.', { type: 'warning' });
            break;
          default:
            toast('Ocorreu uma falha na página, por favor recarregue!', {
              type: 'error',
            });
        }
      } finally {
        setLoading(false);
      }
    },
    [productQuote.id],
  );

  useEffect(() => {
    handleFindPage();
  }, [handleFindPage]);

  return (
    <>
      {loading ? (
        <div>Carregando</div>
      ) : (
        <>
          <CardDescription>
            <h1>
              Você está vendo cotações do produto:
              <span>{productQuote.name}</span>
            </h1>
          </CardDescription>
          <CardsContainer>
            {productsProviders &&
              productsProviders.map(productProvider => (
                <CardProductProvider
                  key={productProvider.id}
                  productProvider={productProvider}
                />
              ))}
          </CardsContainer>
          <Pagination pagination={pagination} onChange={handleFindPage} />
        </>
      )}
    </>
  );
};

export default ProductsProviders;
