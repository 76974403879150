import React, { useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

import {
  Pagination as PaginationType,
  PaginationHandler,
} from '../../@types/pagination';
import { Provider } from '../../@types/provider';
import { Button, HeaderTitle, NotFound, Pagination } from '../../components';
import { useCompany } from '../../hooks/company';
import api from '../../services/api';
import ProviderItem from './ProviderItem';
import SearchProviderModal from './SearchProviderModal';
import {
  Wrapper,
  Header,
  Container,
  SpinnerContainer,
  TableHeader,
  TableContent,
} from './styles';

const DashboardProvider = () => {
  const { addListener, removeListener } = useCompany();

  const [providers, setProviders] = useState<Provider[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [loading, setLoading] = useState(false);

  const [openSearchProviderModal, setOpenSearchProviderModal] = useState(false);

  const handleFindPage = useCallback<PaginationHandler>(async options => {
    try {
      setLoading(true);

      const response = await api.get('/providers', { params: { ...options } });

      const { data } = response;

      setProviders(data.data);
      setPagination(data.pagination);
    } catch (error) {
      const { response } = error;

      if (!response) {
        toast('Ocorreu um erro desconhecido', { type: 'error' });
        return;
      }

      switch (response.status) {
        case 401:
          toast('Você não está autenticado, por favor faça login novamente.', {
            type: 'error',
          });
          break;
        case 403:
          toast('Você não está autorizado.', { type: 'warning' });
          break;
        default:
          toast('Ocorreu uma falha na página, por favor recarregue!', {
            type: 'error',
          });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFindPage();
  }, [handleFindPage]);

  useEffect(() => {
    const listener = addListener(() => handleFindPage());

    return () => {
      if (listener) removeListener(listener.id);
    };
  }, [addListener, handleFindPage, removeListener]);

  const handleToggleSearchProviderModal = useCallback(() => {
    setOpenSearchProviderModal(state => !state);
  }, []);

  const handleAddProvider = useCallback(
    (provider: Provider) => {
      setProviders(state => {
        const newState = [provider, ...state];
        return newState;
      });

      handleToggleSearchProviderModal();
    },
    [handleToggleSearchProviderModal],
  );

  const handleDeleteProvider = useCallback((provider: Provider) => {
    setProviders(state => {
      const newState = [...state];

      const providerIndex = newState.findIndex(({ id }) => id === provider.id);
      newState.splice(providerIndex, 1);

      return newState;
    });
  }, []);

  return (
    <Wrapper>
      <SearchProviderModal
        isOpen={openSearchProviderModal}
        onAddProvider={handleAddProvider}
        closeModal={handleToggleSearchProviderModal}
      />

      <Header>
        <HeaderTitle title="Fornecedores" />
        <Button
          onClick={handleToggleSearchProviderModal}
          containerStyle={{ width: 'auto', height: 40 }}
        >
          Adicionar
        </Button>
      </Header>

      {loading && (
        <SpinnerContainer>
          <Loader type="TailSpin" color="#3784f6" height={45} width={45} />
        </SpinnerContainer>
      )}

      {!loading && (
        <Container>
          {providers.length ? (
            <TableContent>
              <TableHeader>
                <li>Nome</li>
                <li>CNPJ</li>
                <li>Contato</li>
              </TableHeader>
              {providers &&
                providers.map(provider => (
                  <ProviderItem
                    key={provider.id}
                    provider={provider}
                    onDelete={handleDeleteProvider}
                  />
                ))}
            </TableContent>
          ) : (
            <NotFound
              title="Nenhum fornecedor cadastrado"
              description="Cadastre seus fornecedores para que lorem ipsum lorem"
            />
          )}
        </Container>
      )}

      {pagination && (
        <Pagination pagination={pagination} onChange={handleFindPage} />
      )}
    </Wrapper>
  );
};

export default DashboardProvider;
