import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  CSSProperties,
  memo,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';

import { useField } from '@unform/core';

import { Container, Error } from './styles';

// @ts-ignore
export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    ReactInputMaskProps {
  name: string;
  containerStyle?: CSSProperties;
  icon?: React.ComponentType<IconBaseProps>;
  mask?: string | Array<string | RegExp>;
}

const Input: React.FC<InputProps> = ({
  name,
  containerStyle,
  icon: Icon,
  mask,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
      },
      setValue(ref: HTMLInputElement, value) {
        ref.value = value;
      },
      getValue(ref: HTMLInputElement) {
        if (mask) return ref.value.replace(/[^0-9]+/g, '');

        return ref.value;
      },
    });
  }, [fieldName, mask, registerField]);

  return (
    <Container
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      data-testid="input-container"
    >
      {Icon && <Icon size={20} />}
      {mask ? (
        <ReactInputMask
          mask={mask}
          // @ts-ignore
          ref={inputRef}
          defaultValue={defaultValue}
          {...rest}
        />
      ) : (
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
      )}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#FF466B" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default memo(Input);
