import React, { useMemo } from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import logoImg from '../../assets/cotai-05.svg';
import { useAuth } from '../../hooks/auth';
import { Box, Dashboard, Product, Provider, User } from '../Icons';
import MenuItem from './MenuItem';
import { Container, SubMenu, ButtonChangeCompany, ButtonExit } from './styles';

interface MenuProps {
  onToggleChangeCompany: () => void;
}

const Menu: React.FC<MenuProps> = ({ onToggleChangeCompany }) => {
  const { pathname } = useLocation();
  const { signOut } = useAuth();

  const routename = useMemo(() => {
    if (!pathname) return '';

    return pathname.replace('/', '');
  }, [pathname]);

  return (
    <Container>
      <img src={logoImg} alt="CotAí" />
      <SubMenu>
        <MenuItem
          title="Dashboard"
          path="/dashboard"
          icon={Dashboard}
          isActive={routename === 'dashboard'}
        />
        <MenuItem
          title="Cotações"
          path="/dashboard-quote"
          icon={Box}
          isActive={routename === 'dashboard-quote'}
        />
        <MenuItem
          title="Fornecedores"
          path="/dashboard-providers"
          icon={Provider}
          isActive={routename === 'dashboard-providers'}
        />{' '}
        <MenuItem
          title="Produtos"
          path="/dashboard-product"
          icon={Product}
          isActive={routename === 'dashboard-product'}
        />
      </SubMenu>
      <SubMenu>
        <ButtonChangeCompany onClick={onToggleChangeCompany}>
          <FaExchangeAlt size={16} color="#fff" />
        </ButtonChangeCompany>
        <MenuItem
          title="Conta"
          path="/account"
          icon={User}
          isActive={routename === 'account'}
        />
        <ButtonExit
          type="button"
          onClick={() => {
            signOut();
          }}
        >
          Sair
        </ButtonExit>
      </SubMenu>
    </Container>
  );
};

export default Menu;
