import { Subcategory } from './category';
import { SmallCompany } from './company';
import { SmallProposal } from './proposal';

export enum UnitMeasure {
  WEIGHT = 'weight',
  UNIT = 'unit',
  METER = 'meter',
  SQUARE_METER = 'square_meter',
  CUBIC_METER = 'cubic_meter',
}

export interface Product {
  id: string;
  name: string;
  model: string;
  description: string;
  note: string;
  manufacturer: string;
  unitMeasure: UnitMeasure;
  active: boolean;
  subcategory: Subcategory;
  createdAt: string;
  updatedAt: string;
}

export interface ProductQuote {
  id: string;
  product: string;
  name: string;
  note: string;
  model: string;
  manufacturer: string;
  unitMeasure: UnitMeasure;
  quantity: number;
  subcategory: Subcategory;
  createdAt: string;
  updatedAt: string;
}

export interface ProductProposal {
  id: string;
  name: string;
  model: string;
  manufacturer: string;
  unitMeasure: UnitMeasure;
  quantity: number;
  requestedQuantity?: number;
  priceCents?: string;
  deadline: string;
  createdAt: string;
  updatedAt: string;
}

export interface Products {
  createdAt: string;
  id: string;
  manufacturer: string;
  model: string;
  name: string;
  note: string;
  product: string;
  quantity: number;
  unitMeasure: string;
  updatedAt: string;
}

export interface ProductProvider {
  id: string;
  productQuote: ProductQuote;
  productProposal: ProductProposal;
  provider: SmallCompany;
  proposal: SmallProposal;
  createdAt: string;
  updatedAt: string;
}
