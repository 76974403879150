import styled from 'styled-components';

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #3784f6;
    text-align: left;
  }
`;

export const ButtonCloseModal = styled.button`
  background: none;
  border: none;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
