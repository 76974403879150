import { shade } from 'polished';
import styled from 'styled-components';

export const ContentProducts = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    margin-bottom: 5px;
  }
`;

export const AddProductButton = styled.button`
  flex: 1;
  max-width: 100px;
  margin-left: 20px;
  background: #3784f6;
  border: none;
  color: #fff;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#3784f6')};
  }
`;

export const FilterButton = styled.button`
  flex: 1;
  max-width: 80px;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #3784f6;
  border: none;
  color: #fff;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#3784f6')};
  }
`;

export const FormProductsTable = styled.div`
  flex: 1;
`;

export const FormTable = styled.div`
  flex: 1;
  min-height: 215px;
  background: #f1f1f1;
  border-radius: 8px;
  margin-top: 20px;
  padding-top: 10px;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: left;
    list-style: none;

    a {
      text-decoration: none;
      display: flex;
      width: 100%;
      color: #25262d;
      transition: 0.2s;

      &:hover {
        background: #f1f1f1;
      }
    }

    li {
      flex: 2;
      text-align: left;
      color: #959595;
      font-size: 13px;
      font-weight: 500;
      padding: 10px 20px;
      border-bottom: solid 0.3px #f1f1f1;

      button {
        background: #ff466b;
        color: #fff;
        padding: 2px 10px;
        border-radius: 4px;
        border: none;
        font-size: 13px;
        margin-right: 15px;
        transition: 0.2s;

        &:hover {
          background: ${shade(0.2, '#ff466b')};
        }
      }
    }
  }
`;

export const CleanFilter = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;

  span {
    flex: 1;
  }

  button {
    border: none;
    background: none;
    color: #3784f6;

    &:hover {
      color: ${shade(0.2, '#3784f6')};
    }
  }
`;
