import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 1080px;
  height: 310px;
  background: #fff;
  border-radius: 8px;
  margin: 15px;
  padding-top: 10px;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    a {
      text-decoration: none;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      color: #000;
      transition: 0.2s;

      &:hover {
        background: #f1f1f1;
      }
    }

    li {
      font-size: 14px;
      font-weight: 500;
      padding: 10px 20px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  h1 {
    font-weight: 400;
    font-size: 16px;
    color: #3784f6;
  }

  a {
    text-decoration: none;
    padding: 3px 10px;
    color: #fff;
    background: #3784f6;
    border-radius: 20px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#3784f6')};
    }
  }
`;
