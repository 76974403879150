import React, { useState, useEffect, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

import {
  Pagination as PaginationType,
  PaginationHandler,
} from '../../@types/pagination';
import { Product } from '../../@types/product';
import { HeaderTitle, NotFound, Pagination } from '../../components';
import LinkButtom from '../../components/LinkButton';
import api from '../../services/api';
import ProductItem from './ProductItem';
import {
  Wrapper,
  Header,
  SpinnerContainer,
  Container,
  TableContent,
  TableHeader,
} from './styles';

const DashboardProduct: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const handleFindPage = useCallback<PaginationHandler>(async options => {
    try {
      setLoading(true);

      const response = await api.get('/products', { params: { ...options } });

      const { data } = response;

      setProducts(data.data);
      setPagination(data.pagination);
    } catch (error) {
      const { response } = error;

      if (!response) {
        toast('Ocorreu um erro desconhecido', { type: 'error' });
        return;
      }

      switch (response.status) {
        case 401:
          toast('Você não está autenticado, por favor faça login novamente.', {
            type: 'error',
          });
          break;
        case 403:
          toast('Você não está autorizado.', { type: 'warning' });
          break;
        default:
          toast('Ocorreu uma falha na página, por favor recarregue!', {
            type: 'error',
          });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFindPage();
  }, [handleFindPage]);

  return (
    <Wrapper>
      <Header>
        <HeaderTitle title="Produtos" />
        <LinkButtom
          containerStyle={{ width: 'auto', height: 40 }}
          title="Adicionar produto"
          path="/add-product"
        />
      </Header>

      {loading && (
        <SpinnerContainer>
          <Loader type="TailSpin" color="#3784f6" height={45} width={45} />
        </SpinnerContainer>
      )}

      {!loading && (
        <Container>
          {products.length ? (
            <>
              <TableHeader>
                <li style={{ fontWeight: 'bold' }}>Nome</li>
                <li style={{ fontWeight: 'bold' }}>Modelo</li>
                <li style={{ fontWeight: 'bold' }}>Fabricante</li>
                <li style={{ fontWeight: 'bold' }}>Unidade de medida</li>
              </TableHeader>
              <TableContent>
                {products &&
                  products.map(product => (
                    <ProductItem key={product.id} product={product} />
                  ))}
              </TableContent>
            </>
          ) : (
            <NotFound
              title="Nenhum produto cadastrado"
              description="Cadastre seus produtos para criar suas cotações"
            />
          )}
        </Container>
      )}

      {pagination && (
        <Pagination pagination={pagination} onChange={handleFindPage} />
      )}
    </Wrapper>
  );
};

export default DashboardProduct;
