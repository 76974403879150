import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  background: #3784f6;
  border-radius: 8px;
  width: 100%;

  & + div {
    margin-top: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  h1 {
    color: #fff;
  }
`;

export const BottomButton = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  flex: 1;

  button {
    div {
      display: flex;
      flex-direction: row;

      span {
        margin-left: 8px;
      }
    }
  }
`;

export const Divisor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  p {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
  }

  button {
    background: #fff;
    border-radius: 16px;
    padding: 2px 10px;
    border: none;
    color: #3784f6;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#3784f6')};
      color: #fff;
    }
  }
`;
