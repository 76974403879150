import React, {
  useState,
  useImperativeHandle,
  useRef,
  useCallback,
  forwardRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Select } from '../../../components';
import ModalGlobal from '../../../components/ModalGlobal';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { SubmitButton, Title } from './styles';

interface CreateQuoteProduct {
  product: string;
  quantity: number;
}

interface QuoteData {
  title: string;
  description: string;
  note?: string;
  deadline: Date;
  address: string;
  products: CreateQuoteProduct[];
}

export interface ShippingTypeModalHandler {
  setQuoteData: (quoteData: QuoteData) => void;
}

interface ShippingTypeModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

interface ShippingTypeModalFormData {
  shippingType: 'general' | 'my_providers';
}

const ShippingTypeModal: React.ForwardRefRenderFunction<
  ShippingTypeModalHandler,
  ShippingTypeModalProps
> = ({ closeModal, isOpen }, ref) => {
  const selectOptions = [
    { value: 'general', label: 'Todos os fornecedores' },
    { value: 'my_providers', label: 'Meus fornecedores' },
  ];

  const formRef = useRef<FormHandles>(null);

  const [quoteData, setQuoteData] = useState<QuoteData>({} as QuoteData);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useImperativeHandle(
    ref,
    () => ({
      setQuoteData,
    }),
    [],
  );

  const handleSubmit = useCallback<SubmitHandler<ShippingTypeModalFormData>>(
    async formData => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          shippingType: Yup.string()
            .required('Tipo de cotação obrigatório')
            .oneOf(selectOptions.map(({ value }) => value)),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { shippingType } = formData;
        const {
          title,
          description,
          deadline,
          products,
          address,
          note,
        } = quoteData;

        await api.post('quotes', {
          title,
          description,
          shippingType,
          deadline: deadline.toISOString(),
          products,
          address,
          note,
        });

        toast('Cotação criada', { type: 'success' });
        setLoading(false);
        history.push('/dashboard-quote');
      } catch (error) {
        setLoading(false);
        const { response } = error;

        if (response)
          switch (response.status) {
            case 401:
              toast(
                'Você não está autenticado, por favor faça login novamente.',
                { type: 'error' },
              );
              break;
            case 403:
              toast('Você não está autorizado.', { type: 'warning' });
              break;
            default:
              toast('O prazo precisa ser superior a 1 dia.', {
                type: 'error',
              });
          }

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [history, quoteData, selectOptions],
  );

  return (
    <ModalGlobal
      closeModal={closeModal}
      headerTitle="Tipo de envio"
      isOpen={isOpen}
    >
      <Title>Selecione quem deve responder esta cotação?</Title>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        style={{ flex: 1, width: '100%' }}
      >
        <Select
          name="shippingType"
          options={selectOptions}
          defaultValue={selectOptions[0]}
        />

        <SubmitButton type="submit" loading={loading}>
          Enviar cotação
        </SubmitButton>
      </Form>
    </ModalGlobal>
  );
};

export default forwardRef(ShippingTypeModal);
