// TODO eslint fix
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { ProductQuote } from '../../../@types/product';

interface ProductQuoteItemProps {
  productQuote: ProductQuote;
  onSelected: (productQuote: ProductQuote) => void;
}

const ProductQuoteItem: React.FC<ProductQuoteItemProps> = ({
  productQuote,
  onSelected,
}) => {
  return (
    <ul>
      {/* TODO fix eslint */}
      <a onClick={() => onSelected(productQuote)}>
        <li>
          {`${productQuote.name} (${productQuote.subcategory?.category?.title})`}{' '}
        </li>
        <li>{productQuote.manufacturer}</li>
        <li>{productQuote.model} </li>
        <li>{productQuote.quantity}</li>
      </a>
    </ul>
  );
};

export default ProductQuoteItem;
