import styled from 'styled-components';

export const Container = styled.div`
  background-color: #4786fa;
  width: 50px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 20px 0;
  -webkit-box-shadow: 10px 0px 30px -15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 10px 0px 30px -15px rgba(0, 0, 0, 0.12);
  box-shadow: 10px 0px 30px -15px rgba(0, 0, 0, 0.3);
  z-index: 2;

  img {
    padding-top: 20px;
    width: 60px;
  }
`;

export const SubMenu = styled.div`
  width: 100%;
  text-align: center;
`;

export const ButtonExit = styled.button`
  color: #fff;
  background-color: #4786fa;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  transition: 0.2s;

  &:hover {
    background: #ff466b;
  }
`;

export const ButtonChangeCompany = styled.button`
  color: #fff;
  background-color: #4786fa;
  padding: 10px;
  border: none;
  border-radius: 8px;
  transition: 0.2s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const Account = styled.a`
  display: block;
  text-align: center;
  color: #fff;
  fill: #fff;
  border-radius: 4px;
  transition: transform 0.2s;
  text-decoration: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    fill: #fff;
  }
`;
