import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { Provider } from '../../../../@types/provider';
import { SelectAsync, Spinner } from '../../../../components';
import api from '../../../../services/api';
import { formatCnpj } from '../../../../utils/cnpj';
import { debounce } from '../../../../utils/debounceEvent';
import { BottomButton, Column, Container, Divisor } from './styles';

interface ProviderCardProps {
  provider: Provider;
  onRequestAdd?: (provider: Provider) => void;
  onAddProvider?: (provider: Provider) => void;
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  provider,
  onRequestAdd,
  onAddProvider,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const handleSelectInputCategoryChange = useCallback(
    async (title, callback) => {
      try {
        const response = await api.get('categories', { params: { title } });

        const { data: categories } = response.data;

        if (!categories.length)
          toast('Categoria não encontrada', { type: 'info' });
        else formRef.current?.setFieldValue('categories', categories[0]);

        callback(categories);
      } catch (error) {
        toast('Categoria não encontrada', { type: 'error' });

        callback([]);
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async formData => {
      try {
        setLoading(true);

        // eslint-disable-next-line prefer-destructuring
        const categories: string[] = formData.categories;

        const body = {
          id: provider.id,
          categories: categories.map(categoryId => ({
            category: categoryId,
            subcategories: [],
          })),
        };

        const response = await api.post('providers', body);

        toast('Fornecedor adicionado', { type: 'success' });
        onAddProvider && onAddProvider(response.data);
      } catch (error) {
        const { response } = error;

        if (!response) {
          toast('Ocorreu um erro desconhecido', { type: 'error' });
          return;
        }

        switch (response.status) {
          case 401:
            toast(
              'Você não está autenticado, por favor faça login novamente.',
              {
                type: 'error',
              },
            );
            break;
          case 403:
            toast('Acesso negado.', { type: 'warning' });
            break;
          default:
            toast('Ocorreu uma falha na página, por favor recarregue!', {
              type: 'error',
            });
        }
      }
    },
    [onAddProvider, provider],
  );

  return (
    <Container>
      <Divisor>
        <p style={{ fontWeight: 600, fontSize: 15 }}>{provider.name}</p>
        <button
          type="button"
          onClick={() => {
            onRequestAdd && onRequestAdd(provider);
          }}
        >
          Adicionar
        </button>
      </Divisor>

      <Divisor>
        <p>{formatCnpj(provider.cnpj)} </p>
        <p>
          {/* TODO add city/province */}
          Salvador - BA
        </p>
      </Divisor>

      {onAddProvider && (
        <Form onSubmit={handleSubmit}>
          <Divisor>
            <Column>
              <SelectAsync
                title="Escolha uma ou mais categorias"
                name="categories"
                getOptionLabel={categoryOption => categoryOption.title}
                getOptionValue={categoryOption => categoryOption.id}
                loadOptions={debounce(handleSelectInputCategoryChange)}
                cacheOptions
                defaultOptions
                isMulti
              />

              <BottomButton>
                <button type="submit" disabled={loading}>
                  {loading ? (
                    <div>
                      <Spinner color="#3784f6" height={16} width={16} />{' '}
                      <span>Salvando</span>
                    </div>
                  ) : (
                    'Salvar'
                  )}
                </button>
              </BottomButton>
            </Column>
          </Divisor>
        </Form>
      )}
    </Container>
  );
};

export default ProviderCard;
