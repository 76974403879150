import { format, parseISO } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

export function formatDate(
  date: string,
  pattern?: string,
  options?: {
    locale: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string {
  const dateParsed = parseISO(date);

  const dateFormarted = format(dateParsed, pattern || 'dd/MM/yyyy', {
    ...options,
    locale: options?.locale || ptLocale,
  });

  return dateFormarted;
}
