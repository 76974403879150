import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const Content = styled.div`
  flex: 1;
  max-width: 1110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  padding: 40px 20px;

  form {
    flex: 1;
    width: 100%;
  }
`;

export const Space = styled.div`
  display: flex;

  & + div {
    margin-top: 20px;
  }
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const CancelButton = styled.button`
  background: #fff;
  width: 145px;
  height: 40px;
  text-align: center;
  border: solid 3px #f1f1f1;
  border-radius: 8px;
  transition: 0.2s;

  h1 {
    font-size: 18px;
    color: #000;
    font-weight: 500;
  }

  &:hover {
    background: ${shade(0.05, '#f1f1f1')};
    border: solid 2px ${shade(0.05, '#f1f1f1')};
  }
`;
