import { Link } from 'react-router-dom';

import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled(Link)`
  background: #4786fa;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 10px 0 0;

  text-decoration: none;
  text-align: center;
  color: #fff;
  transition: 0.2s;

  &:hover {
    transform: translateY(-5px);
    background: ${shade(0.2, '#4786fa')};
  }

  h2 {
    font-size: 16px;
    font-weight: 600;

    span {
      font-weight: 400;
      padding-right: 5px;
    }
  }
`;
