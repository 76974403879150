import styled, { css } from 'styled-components';

interface StatusProps {
  statusCode?: string;
}

export const Status = styled.li<StatusProps>`
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  p {
    width: 120px;
    height: 20px;
    border-radius: 10px;

    ${({ statusCode }) =>
      statusCode === 'Fechado'
        ? css`
            background: #effcf5;
            color: #2fa06c;
          `
        : statusCode === 'Cancelado'
        ? css`
            background: #feeff2;
            color: #ff0057;
          `
        : statusCode === 'Em Análise'
        ? css`
            background: #fef7ef;
            color: #f6a337;
          `
        : css`
            background: #eeeeee;
            color: #828282;
          `}
  }
`;
