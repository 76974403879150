import { ToastContainer } from 'react-toastify';

import styled from 'styled-components';

export const Container = styled(ToastContainer)`
  & .Toastify__toast {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    padding: 10px 10px 15px 10px;
  }
  & .Toastify__toast--error {
    background: #fb6358;
  }

  & .Toastify__toast--success {
    background: #1ed760;
  }
  & .Toastify__toast--warning {
    background: #707070;
  }
  & .Toastify__toast--info {
    background: #3784f6;
  }
  & .Toastify__progress-bar {
    height: 3px;
  }
`;
