import React, { ButtonHTMLAttributes } from 'react';

import { CSSProperties } from 'styled-components';

import Spinner from '../Spinner';
import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  containerStyle?: CSSProperties;
  clear?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  containerStyle,
  loading,
  ...rest
}) => (
  <Container style={containerStyle} type="button" {...rest}>
    {loading ? (
      <Spinner type="TailSpin" color="#fff" height={25} width={25} />
    ) : (
      children
    )}
  </Container>
);

export default Button;
