import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f5f7fc;
  border-radius: 4px;
  border: 1px #3784f6 solid;
  padding: 10px 15px;
`;

export const ProviderInfoRow = styled.div`
  display: flex;
  justify-content: space-between;

  & + div {
    margin-top: 8px;
  }
`;

interface DetailProps {
  justify: 'flex-start' | 'center' | 'flex-end';
}

export const Detail = styled.div<DetailProps>`
  display: flex;
  flex: 1;
  justify-content: ${props => props.justify};

  span {
    margin-left: 4px;
    font-weight: 500;
  }

  h6 {
    color: #ffc107;
    font-weight: 500;
    font-size: 14px;
  }

  button {
    border: none;
    background: none;
    color: #ff466b;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: ${shade(0.2, '#FF466B')};
    }

    svg {
      margin-right: 4px;
    }
  }
`;
