import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

const BackButtom: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <button type="button" onClick={() => history.goBack()}>
        <IoIosArrowBack size={17} color="#fff" />
        <h1>Voltar</h1>
      </button>
    </Container>
  );
};

export default BackButtom;
