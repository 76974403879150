import React, { useCallback, useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import { Category, Subcategory } from '../../../@types/category';
import { Button, ModalGlobal, SelectAsync } from '../../../components';
import {
  AsyncSelectHandler,
  LoadOptionsHandler,
} from '../../../components/Select/Async';
import api from '../../../services/api';
import { debounce } from '../../../utils/debounceEvent';

interface SelectSubcategoryModalProps {
  openModal: boolean;
  onSelect: (subcategory?: Subcategory) => void;
  closeModal: () => void;
}

interface SelectSubcategoryModalFormData {
  category: string;
  subcategory: string;
}

export const SelectSubcategoryModal: React.FC<SelectSubcategoryModalProps> = ({
  onSelect,
  openModal,
  closeModal,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [categoryLoading, setCategoryLoading] = useState(true);

  const [subcategory, setSubcategory] = useState<Subcategory>();
  const [category, setCategory] = useState<Category>();

  const handleSelectInputCategoryChange = useCallback(async (title, cb) => {
    try {
      setCategoryLoading(true);

      const response = await api.get('categories', { params: { title } });

      const { data: categories } = response.data;

      if (!categories.length)
        toast('Categoria não encontrada', { type: 'info' });
      else formRef.current?.setFieldValue('category', categories[0]);

      cb(categories);
    } catch (error) {
      toast('Categoria não encontrada', { type: 'error' });

      cb([]);
    } finally {
      setCategoryLoading(false);
    }
  }, []);

  const handleSelectInputSubcategoryChange = useCallback<LoadOptionsHandler>(
    async (title, callback) => {
      try {
        const categoryId = formRef.current?.getFieldValue('category');

        const response = await api.get('subcategories', {
          params: { title, category: categoryId },
        });

        const { data: subcategories } = response.data;

        if (!subcategories.length)
          toast('Subcategoria não encontrada', { type: 'info' });
        else formRef.current?.setFieldValue('subcategory', subcategories[0]);

        if (callback) callback(subcategories);
        return subcategories;
      } catch (error) {
        toast('Subcategoria não encontrada', { type: 'error' });

        if (callback) callback([]);
        return [];
      }
    },
    [],
  );

  const subcategorySelectRef = useRef<AsyncSelectHandler>(null);

  useEffect(() => {
    if (category && subcategorySelectRef.current)
      subcategorySelectRef.current.reloadOptions(
        handleSelectInputSubcategoryChange,
      );
  }, [category, handleSelectInputSubcategoryChange]);

  const handleSubmit = useCallback<
    SubmitHandler<SelectSubcategoryModalFormData>
  >(() => {
    onSelect(subcategory);

    closeModal();
  }, [closeModal, onSelect, subcategory]);

  return (
    <ModalGlobal
      headerTitle="Filtro de produtos"
      isOpen={openModal}
      closeModal={closeModal}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <SelectAsync
          title="Categoria:"
          name="category"
          getOptionLabel={categoryOption => categoryOption.title}
          getOptionValue={categoryOption => categoryOption.id}
          loadOptions={debounce(handleSelectInputCategoryChange)}
          containerStyle={{ width: 400, marginTop: 20 }}
          cacheOptions
          defaultOptions
          // @ts-ignore
          onChange={setCategory}
        />

        {!categoryLoading && (
          <SelectAsync
            ref={subcategorySelectRef}
            title="Subcategoria:"
            name="subcategory"
            getOptionLabel={subcategoryOption => subcategoryOption.title}
            getOptionValue={subcategoryOption => subcategoryOption.id}
            loadOptions={debounce(handleSelectInputSubcategoryChange)}
            // @ts-ignore
            onChange={setSubcategory}
            containerStyle={{ width: 400, marginTop: 20 }}
            cacheOptions
            defaultOptions
          />
        )}
        {!categoryLoading && (
          <Button containerStyle={{ marginTop: 20 }} type="submit">
            Selecionar
          </Button>
        )}
      </Form>
    </ModalGlobal>
  );
};
