import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    color: #c7c7c7;
  }

  h1 {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 500;
    color: #c7c7c7;
  }
`;
