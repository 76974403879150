import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  margin-right: 60px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex: 2;
    justify-content: center;
  }
`;

export const HeaderTitle = styled.h3`
  flex: 3;
  color: #3784f6;
  font-size: 14px;
  font-weight: 600;
`;

export const HeaderButton = styled.button`
  background: #3784f6;
  padding: 0 12px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  border: 2px #3784f6 solid;
  border-radius: 20px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#3784f6')};
    border-color: ${shade(0.2, '#3784f6')};
  }
`;

export const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
`;

export const CategoryWrapper = styled.div``;

export const CategoryName = styled.h6`
  font-size: 13px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 8px;
`;

export const SubcategoryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  div {
    display: flex;
    flex: 1;
  }
`;

export const SubcategoryName = styled.span`
  font-size: 12px;
  background: #f5f7fc;
  padding: 4px 8px;
  border-radius: 4px;

  position: relative;

  &:hover {
    button {
      display: flex;
    }
  }

  button {
    display: none;

    position: absolute;
    top: -15px;
    right: -12px;

    align-items: center;
    justify-content: center;
    padding: 0;
    background: #ff466b;
    border: 1px #ff466b solid;
    border-radius: 50%;
  }
`;

export const ViewMoreButton = styled.button`
  font-size: 12px;
  font-weight: 600;
  border: none;
  background: none;
  color: #3784f6;
  padding: 4px 8px;

  &:hover {
    color: ${shade(0.2, '#3784f6')};
  }
`;
