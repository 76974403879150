import * as React from 'react';

function Alert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={512}
      viewBox="0 0 512 512"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M512 72.638v366.724c0 5.687-4.615 10.302-10.302 10.302H10.302C4.615 449.664 0 445.049 0 439.362V72.638c0-5.687 4.615-10.302 10.302-10.302h491.396c5.687 0 10.302 4.615 10.302 10.302z"
        fill="#e8e6ed"
      />
      <path
        d="M512 72.638v366.724c0 5.687-4.615 10.302-10.302 10.302H378.303c113.979-98.66 111.445-328.061 108.756-387.327h14.639C507.385 62.336 512 66.951 512 72.638z"
        fill="#d1d0d8"
      />
      <path
        d="M512 72.638v54.631H0V72.638c0-5.687 4.615-10.302 10.302-10.302h491.396c5.687 0 10.302 4.615 10.302 10.302z"
        fill="#ff5677"
      />
      <path
        d="M512 72.638v54.631h-24.549c.845-28.289.237-51.19-.391-64.932h14.639C507.385 62.336 512 66.951 512 72.638z"
        fill="#ff2953"
      />
      <g fill="#be224e">
        <path d="M41.255 102.531h-.116a7.726 7.726 0 110-15.452h.116a7.726 7.726 0 110 15.452zM64.581 102.531h-.116a7.726 7.726 0 110-15.452h.116a7.726 7.726 0 110 15.452zM87.907 102.531h-.116a7.726 7.726 0 110-15.452h.116a7.726 7.726 0 110 15.452zM470.862 102.531h-14.377a7.726 7.726 0 110-15.452h14.377a7.726 7.726 0 110 15.452z" />
      </g>
      <g>
        <path
          d="M330.77 240.028c0 17.667-6.123 33.903-16.367 46.69-13.703 17.115-34.772 28.08-58.402 28.08s-44.699-10.964-58.402-28.08c-10.245-12.787-16.367-29.024-16.367-46.69 0-41.297 33.473-74.77 74.77-74.77s74.768 33.473 74.768 74.77z"
          fill="#ff5677"
        />
        <path
          d="M330.77 240.028c0 17.667-6.123 33.903-16.367 46.69-13.703 17.115-34.772 28.08-58.402 28.08-15.105 0-29.173-4.477-40.923-12.198a74.495 74.495 0 0019.975 2.711c23.63 0 44.699-10.964 58.402-28.079 10.245-12.787 16.367-29.024 16.367-46.69 0-26.191-13.46-49.233-33.847-62.581 31.595 8.729 54.795 37.687 54.795 72.067z"
          fill="#ff2953"
        />
      </g>
      <path
        d="M283.282 348.386h-54.563a7.726 7.726 0 110-15.452h54.563a7.726 7.726 0 110 15.452z"
        fill="#ff2953"
      />
      <g fill="#aaaab2">
        <path d="M322.714 400.737h-193.11a7.726 7.726 0 110-15.452h193.111a7.726 7.726 0 11-.001 15.452zM382.396 400.737h-28.314a7.726 7.726 0 110-15.452h28.314a7.726 7.726 0 110 15.452zM191.287 424.264h-61.684a7.726 7.726 0 110-15.452h61.684a7.726 7.726 0 110 15.452zM382.396 424.264H220.595a7.726 7.726 0 110-15.452h161.801a7.726 7.726 0 110 15.452z" />
      </g>
      <path
        d="M266.927 240.028l17.714-17.714a7.727 7.727 0 00-10.927-10.927L256 229.102l-17.714-17.714a7.727 7.727 0 00-10.927 10.927l17.714 17.714-17.714 17.714a7.725 7.725 0 000 10.927c1.509 1.509 3.486 2.263 5.463 2.263s3.955-.755 5.463-2.263L256 250.955l17.714 17.714c1.509 1.509 3.486 2.263 5.463 2.263s3.955-.755 5.463-2.263a7.725 7.725 0 000-10.927z"
        fill="#e8e6ed"
      />
    </svg>
  );
}

export default Alert;
