import React, { useState } from 'react';

import { BackButton } from '../../components';
import {
  Container,
  Header,
  Content,
  TableContent,
  HeaderTitle,
  Payment,
  DetailCard,
  LeftDetail,
  InfoDetail,
  NoteDetail,
  RightDetail,
  PriceDetail,
  AcceptButton,
  Table,
  Li,
} from './styles';

interface ProposalProps {
  id: string;
  title: string;
  payment: string;
  products: Product[];
  deadline: string;
  shippingPrice: string;
  totalValue: string;
  note?: string;
}

interface Product {
  id: string;
  product: string;
  manufacturer: string;
  model: string;
  amount: string;
  price: string;
}

const ProposalQuote: React.FC = () => {
  const [quote] = useState({
    id: '1',
    title: 'Nova sala TI',
    payment: 'À vista',
    products: [
      {
        id: '1',
        product: 'Toner',
        manufacturer: 'HP',
        model: 'SHE 5000',
        amount: '13',
        price: '1.399,99',
      },
      {
        id: '2',
        product: 'Toner',
        manufacturer: 'HP',
        model: 'SHE 5000',
        amount: '13',
        price: '1.399,99',
      },
    ],
    deadline: '27-10-2020',
    shippingPrice: '1.500,88',
    totalValue: '123.456,55',
    note: 'nada',
  });

  return (
    <Container>
      <Header>
        <div>
          <HeaderTitle>Proposta {quote.title}</HeaderTitle>
          <Payment>
            <h2>Forma de pagamento: </h2>
            <h1> {quote.payment}</h1>
          </Payment>
        </div>
        <BackButton />
      </Header>
      <Content>
        <Table>
          <ul style={{ color: '#232242' }}>
            <li style={{ fontWeight: 'bold', color: '#232242' }}>Produto</li>
            <li style={{ fontWeight: 'bold', color: '#232242' }}>Fabricante</li>
            <li style={{ fontWeight: 'bold', color: '#232242' }}>Modelo</li>
            <Li style={{ fontWeight: 'bold', color: '#232242' }}>Quantidade</Li>
            <Li style={{ fontWeight: 'bold', color: '#232242' }}>
              Valor unitário
            </Li>
          </ul>
          <TableContent>
            {quote &&
              quote.products.map(repo => (
                <ul key={repo.id}>
                  <li>{repo.product} </li>
                  <li>{repo.manufacturer}</li>
                  <li>{repo.model}</li>
                  <li>{repo.amount}</li>
                  <li>R$ {repo.price}</li>
                </ul>
              ))}
          </TableContent>
        </Table>

        <DetailCard>
          <LeftDetail>
            <div style={{ display: 'flex' }}>
              <InfoDetail>
                <h3>Prazo de entrega:</h3>
                <h1>{quote.deadline}</h1>
              </InfoDetail>
              <InfoDetail>
                <h3>Valor do frete:</h3>
                <h1>R$ {quote.shippingPrice}</h1>
              </InfoDetail>
            </div>
            <NoteDetail>
              <h3>Observação:</h3>
              <h1>{quote.note}</h1>
            </NoteDetail>
          </LeftDetail>
          <RightDetail>
            <PriceDetail>
              <h3>Valor total da cotação:</h3>
              <h1>
                <span>R$ </span> {quote.totalValue}
              </h1>
            </PriceDetail>
            <AcceptButton type="button">Aprovar</AcceptButton>
          </RightDetail>
        </DetailCard>
      </Content>
    </Container>
  );
};

export default ProposalQuote;
