import styled from 'styled-components';

export const Container = styled.div`
  label {
    display: block;
    cursor: pointer;

    input {
      margin-right: 8px;
    }

    & + label {
      margin-top: 8px;
    }
  }
`;
