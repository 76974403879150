import React, {
  useCallback,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  RefObject,
} from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { FormHandles, Scope } from '@unform/core';

import { Subcategory } from '../../../@types/category';
import { ProductQuote } from '../../../@types/product';
import {
  InputTitle,
  SelectAsync,
  AsyncSelectHandler,
  LoadOptionsHandler,
} from '../../../components';
import api from '../../../services/api';
import { debounce } from '../../../utils/debounceEvent';
import {
  AddProductButton,
  CleanFilter,
  ContentProducts,
  FilterButton,
  FormProductsTable,
  FormTable,
} from './styles';

interface AddProductFormData {
  category: string;
  product: string;
  quantity: string;
}

interface ProductFormProps {
  formRef: RefObject<FormHandles>;
  productQuotes: ProductQuote[];
  setProductQuotes: Dispatch<SetStateAction<ProductQuote[]>>;
  subcategoryFilter?: Subcategory;
  onCleanSubcategoryFilter: () => void;
  onSubcategoryFilter: () => void;
}

const ProductForm: React.FC<ProductFormProps> = ({
  formRef,
  productQuotes,
  setProductQuotes,
  subcategoryFilter,
  onCleanSubcategoryFilter,
  onSubcategoryFilter,
}) => {
  const selectAsyncRef = useRef<AsyncSelectHandler>(null);

  const handleSubmit = useCallback(() => {
    const selectRef = formRef.current?.getFieldRef('addProduct.product');
    const currentProduct = selectRef.select.state.value;

    const formData = formRef.current?.getData() as {
      addProduct: AddProductFormData;
    };
    const { addProduct } = formData;

    if (
      currentProduct &&
      addProduct.quantity !== '' &&
      Number(addProduct.quantity) > 0
    ) {
      setProductQuotes(state => {
        const checkIndex = state.findIndex(
          product => product.id === currentProduct.id,
        );

        const newState = [...state];

        if (checkIndex >= 0) {
          const newProduct = {
            ...newState[checkIndex],
            quantity:
              newState[checkIndex].quantity + Number(addProduct.quantity),
          };

          newState[checkIndex] = newProduct;
        } else {
          const newProduct = {
            ...currentProduct,
            subcategory: currentProduct.subcategory.title,
            quantity: Number(addProduct.quantity),
          };

          newState.push(newProduct);
        }

        return newState;
      });

      formRef.current?.setFieldValue('addProduct.product', '');
      formRef.current?.setFieldValue('addProduct.quantity', '1');
    } else
      toast('Selecione o produto e informe uma quantidade positiva', {
        type: 'info',
      });
  }, [formRef, setProductQuotes]);

  const handleSelectProductChange = useCallback<LoadOptionsHandler>(
    async (search, callback) => {
      try {
        const subcategory = subcategoryFilter && subcategoryFilter.id;

        const name = search === '' ? undefined : search;

        const response = await api.get(`products`, {
          params: { name, subcategory },
        });

        const { data: products } = response.data;

        if (!products.length) toast('Produto não encontrado', { type: 'info' });
        else formRef.current?.setFieldValue('addProduct.product', products[0]);

        if (callback) callback(products);
        return products;
      } catch (error) {
        toast('Produto não encontrado', { type: 'error' });

        if (callback) callback([]);
        return [];
      }
    },
    [formRef, subcategoryFilter],
  );

  useEffect(() => {
    selectAsyncRef.current?.reloadOptions(handleSelectProductChange);
  }, [handleSelectProductChange, subcategoryFilter]);

  const handleProductDelete = useCallback(
    (index: number) => {
      setProductQuotes(state => {
        const newProducts = [...state];

        newProducts.splice(index, 1);

        return newProducts;
      });
    },
    [setProductQuotes],
  );

  return (
    <FormProductsTable>
      <ContentProducts>
        <Scope path="addProduct">
          <SelectAsync
            ref={selectAsyncRef}
            title="Produto:"
            name="product"
            getOptionLabel={product => product.name}
            getOptionValue={product => product.id}
            loadOptions={debounce(handleSelectProductChange, 1500)}
            cacheOptions
            defaultOptions
            containerStyle={{ paddingRight: 20 }}
          />
          <FilterButton type="button" onClick={onSubcategoryFilter}>
            <FaFilter size={20} />
          </FilterButton>
          <InputTitle
            title="Quantidade:"
            name="quantity"
            defaultValue="1"
            type="number"
            containerStyle={{ width: 80 }}
            maxLength={5}
          />
          <AddProductButton type="button" onClick={handleSubmit}>
            <FaPlus size={20} />
            <p>Adicionar</p>
          </AddProductButton>
        </Scope>
      </ContentProducts>
      {subcategoryFilter && (
        <CleanFilter>
          <span>{`Filtrando por: ${subcategoryFilter.title} (${subcategoryFilter.category?.title})`}</span>
          <button type="button" onClick={onCleanSubcategoryFilter}>
            Limpar filtros
          </button>
        </CleanFilter>
      )}
      <FormTable>
        <ul>
          <li>Produto:</li>
          <li>Fabricante:</li>
          <li>Categoria:</li>
          <li>Quantidade</li>
          <li style={{ flex: 1 }}>Ações</li>
        </ul>
        {productQuotes.map((product, index) => (
          <Scope path={`products[${index}]`} key={product.id}>
            <ul>
              <li>{product.name} </li>
              <li>{product.manufacturer} </li>
              <li>{product.subcategory} </li>
              <li>{product.quantity} </li>
              <li style={{ textAlign: 'right', flex: 1 }}>
                <button
                  type="button"
                  onClick={() => {
                    handleProductDelete(index);
                  }}
                >
                  Excluir
                </button>
              </li>
            </ul>
          </Scope>
        ))}
      </FormTable>
    </FormProductsTable>
  );
};

export default ProductForm;
