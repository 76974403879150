import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Space = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const ButtonReset = styled.button`
  margin-right: 20px;
  background: #c4c4c4;
  width: 175px;
  height: 50px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  border: solid 2px #c4c4c4;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#C4C4C4')};
    border: solid 2px ${shade(0.2, '#C4C4C4')};
  }
`;

export const Companies = styled.div`
  flex: 1;
  width: 100%;

  h3 {
    text-align: left;
    font-size: 13px;
    color: #707070;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

export const Table = styled.div`
  margin-top: 10px;
  flex: 1;
  width: 100%;
  background: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    border-radius: 8px;
    transition: 0.2s;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;

export const TableContent = styled.div`
  min-height: 300px;
  padding-top: 10px;
  overflow: auto;
  border-radius: 8px;
  background: #f1f1f1;
  transition: 0.2s;

  &::-webkit-scrollbar {
    display: auto;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    border: 3px #f1f1f1 solid;

    &:hover {
      border: 3px #4786fa solid;
    }

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;
