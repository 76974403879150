import * as React from 'react';

function Box(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 24H2v-9h5v1.735c.638-.198 1.322-.495 1.765-.689.642-.28 1.259-.417 1.887-.417 1.214 0 2.205.499 4.303 1.205.64.214 1.076.716 1.175 1.306 1.124-.863 2.92-2.257 2.937-2.27a1.92 1.92 0 011.2-.434c.952 0 1.751.763 1.751 1.708 0 .49-.219.977-.627 1.356a215.314 215.314 0 01-3.387 3.074c-.56.501-1.066.952-1.548 1.393-.749.687-1.518 1.006-2.421 1.006-.405 0-.832-.065-1.308-.2C9.954 22.99 8.243 22.737 7 22.668V24zm-1-8H3v7h3v-7zm1 5.664c2.092.118 4.405.696 5.999 1.147.817.231 1.761.354 2.782-.581 1.279-1.172 2.722-2.413 4.929-4.463.824-.765-.178-1.783-1.022-1.113 0 0-2.961 2.299-3.689 2.843-.379.285-.695.519-1.148.519-.107 0-.223-.013-.349-.042-.655-.151-1.883-.425-2.755-.701-.575-.183-.371-.993.268-.858.447.093 1.594.35 2.201.52 1.017.281 1.276-.867.422-1.152-.562-.19-.537-.198-1.889-.665-1.301-.451-2.214-.753-3.585-.156-.639.278-1.432.616-2.164.814v3.888zm3.79-19.913L14 0l7 3.86v7.677l-7 3.735-7-3.735V3.818l3.784-2.064.002-.005.004.002zm2.71 6.015L8 4.902v6.035l5.5 2.935V7.766zm1 .001v6.105l5.5-2.935v-6l-5.5 2.83zm1.77-2.035L10.8 2.884 8.598 4.086l5.404 2.813 2.268-1.167zm-4.412-3.425l5.501 2.864 2.042-1.051-5.404-2.979-2.139 1.166z" />
    </svg>
  );
}

export default Box;
