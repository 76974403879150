import React, { useState, useEffect, useCallback } from 'react';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import {
  Pagination as PaginationType,
  PaginationHandler,
} from '../../@types/pagination';
import { SmallQuote } from '../../@types/quote';
import {
  HeaderTitle,
  LinkButton,
  Pagination,
  Spinner,
  NotFound,
} from '../../components';
import { ListenerHandler, useCompany } from '../../hooks/company';
import api from '../../services/api';
import { formatStatus } from '../../utils/quote';
import QuoteItem from './QuoteItem';
import {
  Container,
  Header,
  TopContent,
  TableContent,
  CardInfo,
  CardDivisor,
} from './styles';

const DashboardQuote: React.FC = () => {
  const { currentCompany, addListener, removeListener } = useCompany();

  const [quotes, setQuotes] = useState<SmallQuote[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [loading, setLoading] = useState(true);

  const handleFindPage = useCallback<PaginationHandler>(async options => {
    try {
      setLoading(true);

      const response = await api.get('/quotes', { params: { ...options } });

      const { data } = response;

      const quotesParsed = data.data.map((quote: SmallQuote) => ({
        ...quote,
        status: formatStatus(quote.status),
        deadline: format(parseISO(quote.deadline), "dd 'de' MMMM 'de' yyyy", {
          locale: ptLocale,
        }),
      }));

      setQuotes(quotesParsed);
      setPagination(data.pagination);
    } catch (error) {
      const { response } = error;

      if (!response) {
        toast('Ocorreu um erro desconhecido', { type: 'error' });
        return;
      }

      switch (response.status) {
        case 401:
          toast('Você não está autenticado, por favor faça login novamente.', {
            type: 'error',
          });
          break;
        case 403:
          toast('Você não está autorizado.', { type: 'warning' });
          break;
        default:
          toast('Ocorreu uma falha na página, por favor recarregue!', {
            type: 'error',
          });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOnChangeCompany = useCallback<ListenerHandler>(() => {
    handleFindPage();
  }, [handleFindPage]);

  useEffect(() => {
    const listener = addListener(handleOnChangeCompany);

    return () => {
      if (listener) removeListener(listener.id);
    };
  }, [addListener, handleOnChangeCompany, removeListener]);

  useEffect(() => {
    if (!quotes.length && !pagination && currentCompany) handleFindPage();
  }, [quotes, currentCompany, handleFindPage, pagination]);

  return (
    <Container>
      <Header>
        <HeaderTitle title="Cotações" />
        <LinkButton path="/add-quotation" title="Criar cotação">
          <FaPlus size={14} />
        </LinkButton>
      </Header>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {quotes.length ? (
            <>
              <TopContent>
                <TableContent>
                  <ul style={{ color: '#000' }}>
                    <li style={{ fontWeight: 'bold' }}>Título</li>
                    <li style={{ fontWeight: 'bold' }}>Propostas</li>
                    <li style={{ fontWeight: 'bold' }}>Prazo de entrega</li>
                    <li style={{ fontWeight: 'bold' }}>Status</li>
                  </ul>
                  {quotes &&
                    quotes.map(quote => (
                      <QuoteItem key={quote.id} quote={quote} />
                    ))}
                </TableContent>
                <Pagination pagination={pagination} onChange={handleFindPage} />
              </TopContent>
            </>
          ) : (
            <CardInfo>
              <NotFound
                title="Nenhuma cotação foi encontrada"
                description="A empresa selecionada ainda não solicitou nenhuma cotação"
              >
                <CardDivisor>
                  <LinkButton
                    path="/add-quotation"
                    title="Criar cotação"
                    containerStyle={{ width: 280 }}
                  />
                </CardDivisor>
              </NotFound>
            </CardInfo>
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardQuote;
