import styled from 'styled-components';

import { Button } from '../../../components';

export const SubmitButton = styled(Button)`
  margin-top: 20px;
`;

export const Title = styled.p`
  margin-top: 25px;
`;
