import styled, { css } from 'styled-components';

interface ContainerProps {
  isExpanded: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${props =>
    props.isExpanded &&
    css`
      box-shadow: 16px 16px 37px #dbdbdb, -16px -16px 37px #e9e9e9;
      border-radius: 8px;
    `}
`;

interface HeaderProps {
  isExpanded: boolean;
}

export const Header = styled.button<HeaderProps>`
  width: 100%;
  background: none;
  border: 2px #fff solid;
  border-radius: 4px;
  text-align: left;
  transition: 0.2s;

  &:hover {
    ${props =>
      !props.isExpanded &&
      css`
        border-color: #3784f6;
      `}
  }

  div {
    display: flex;
    justify-content: space-between;

    padding: 10px 20px 0 20px;

    h2 {
      font-size: 14px;
      font-weight: 600;
      color: #3784f6;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;

    li {
      display: flex;
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      align-items: center;

      &:last-child {
        justify-content: space-between;

        svg {
          margin-right: 20px;
        }
      }
    }
  }
`;

export const Content = styled.div`
  padding: 20px;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;
