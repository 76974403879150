import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  Pagination as PaginationType,
  PaginationHandler,
} from '../../../@types/pagination';
import { Provider } from '../../../@types/provider';
import {
  Input,
  ModalGlobal,
  Select,
  Button,
  Pagination,
} from '../../../components';
import api from '../../../services/api';
import { debounce } from '../../../utils/debounceEvent';
import getValidationErrors from '../../../utils/getValidationErrors';
import { cnpjValidator } from '../../../utils/validators';
import ProviderCard from './ProviderCard';
import { Row, SearchError, ListProviders } from './styles';

interface SearchProviderModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onAddProvider: (provider: Provider) => void;
}

interface SearchProviderModalFormData {
  name?: string;
  cnpj?: string;
  type: string;
}

const SearchProviderModal: React.FC<SearchProviderModalProps> = ({
  isOpen,
  closeModal,
  onAddProvider,
}) => {
  const formRef = useRef<FormHandles>(null);
  const formRefSendMail = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const [searchError, setSearchError] = useState(false);
  const [searchProvider, setSearchProvider] = useState(true);

  const [providers, setProviders] = useState<Provider[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [searchType, setSearchType] = useState('cnpj');
  const [newProvider, setNewProvider] = useState<Provider>();

  const handleToggleSearch = useCallback(() => {
    setSearchError(state => !state);
    setSearchProvider(state => !state);
  }, []);

  const handlePageChange = useCallback<PaginationHandler>(
    async options => {
      try {
        const formData = formRef.current?.getData();

        const response = await api.get('search-providers', {
          params: { size: 3, ...formData, ...options },
        });

        const { data, pagination: paginationResponse } = response.data;

        if (!data.length) handleToggleSearch();
        else setSearchError(false);

        setProviders(data);
        setPagination(paginationResponse);
      } catch (error) {
        const { response } = error;

        switch (response.status) {
          case 401:
            toast(
              'Você não está autenticado, por favor faça login novamente.',
              {
                type: 'error',
              },
            );
            break;
          case 403:
            toast('Você não está autorizado.', { type: 'warning' });
            break;
          default:
            toast('Falha na página, por favor recarregue!', { type: 'error' });
        }
      } finally {
        setLoading(false);
      }
    },
    [handleToggleSearch],
  );

  const handleSubmit = useCallback<SubmitHandler<SearchProviderModalFormData>>(
    async formData => {
      try {
        setNewProvider(undefined);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string()
            .oneOf(['name', 'cnpj'], 'Opção inválida')
            .required(),
          name: Yup.string().when('type', {
            is: 'name',
            then: Yup.string().required('Nome não informado'),
          }),
          cnpj: Yup.string()
            .when('type', {
              is: 'cnpj',
              then: Yup.string().required('CNPJ não informado'),
            })
            .when('type', {
              is: 'cnpj',
              then: Yup.string().test(
                'is-cnpj',
                'CNPJ inválido',
                cnpjValidator,
              ),
            }),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        handlePageChange();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [handlePageChange],
  );

  const handleInputChange = useCallback(() => {
    handlePageChange();
  }, [handlePageChange]);

  const handleSubmitSendMail = useCallback<SubmitHandler>(
    async ({ email }) => {
      try {
        setLoading(true);

        formRefSendMail.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('E-mail não informado'),
        });

        await schema.validate(
          { email },
          {
            abortEarly: false,
          },
        );

        await api.post('companies/send-invite-providers', { email });
        toast('Convite enviado', { type: 'success' });
        handleToggleSearch();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefSendMail.current?.setErrors(errors);

          return;
        }

        const { response } = error;

        switch (response.status) {
          case 401:
            toast(
              'Você não está autenticado, por favor faça login novamente.',
              {
                type: 'error',
              },
            );
            break;
          case 403:
            toast('Você não está autorizado.', { type: 'warning' });
            break;
          default:
            toast('Falha na página, por favor recarregue!', { type: 'error' });
        }
      } finally {
        setLoading(false);
      }
    },
    [handleToggleSearch],
  );

  const handleSelectChange = useCallback(type => {
    setSearchType(type.value);
  }, []);

  useEffect(() => {
    handlePageChange();
  }, [handlePageChange]);

  useEffect(() => {
    if (!isOpen) {
      formRefSendMail.current?.setData({ email: '' });
      formRef.current?.setData({ type: 'cnpj', name: '', cnpj: '' });

      setSearchType('cnpj');
      setSearchError(false);
      setSearchProvider(true);
      setNewProvider(undefined);

      handlePageChange();
    }
  }, [isOpen, handlePageChange]);

  return (
    <ModalGlobal
      closeModal={closeModal}
      isOpen={isOpen}
      headerTitle="Adicione seus fornecedores"
    >
      {searchProvider && (
        <div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <Select
                name="type"
                options={[
                  { value: 'cnpj', label: 'CNPJ' },
                  { value: 'name', label: 'Nome' },
                ]}
                defaultValue={{ value: 'cnpj', label: 'CNPJ' }}
                onChange={handleSelectChange}
                containerStyle={{ width: 100, marginRight: 8 }}
              />

              {searchType === 'cnpj' && (
                <Input
                  mask="99.999.999/9999-99"
                  name="cnpj"
                  placeholder="Digite o CNPJ do fornecedor"
                  containerStyle={{ width: 400, marginRight: 8 }}
                  onChange={debounce(handleInputChange, 2500)}
                />
              )}

              {searchType === 'name' && (
                <Input
                  name="name"
                  placeholder="Digite o nome do fornecedor"
                  containerStyle={{ width: 400, marginRight: 8 }}
                  onChange={debounce(handleInputChange)}
                />
              )}

              <Button type="submit" containerStyle={{ width: 'auto' }}>
                <FiSearch size={22} color="#fff" />
              </Button>
            </Row>
          </Form>

          {newProvider && (
            <ListProviders>
              <ProviderCard
                provider={newProvider}
                onAddProvider={onAddProvider}
              />
            </ListProviders>
          )}

          {!newProvider && (
            <ListProviders>
              {providers.map((provider, index) => (
                <>
                  <ProviderCard
                    key={provider.id}
                    provider={provider}
                    onRequestAdd={setNewProvider}
                  />
                  {pagination &&
                    pagination.last &&
                    index === providers.length - 1 && (
                      <Button clear onClick={handleToggleSearch}>
                        Não encontrei meu fornecedor
                      </Button>
                    )}
                </>
              ))}
            </ListProviders>
          )}
          {!newProvider && (
            <Pagination pagination={pagination} onChange={handlePageChange} />
          )}
        </div>
      )}

      {searchError && (
        <SearchError>
          <h1>Não conseguimos localizar nenhum fornecedor com esses dados</h1>
          <h2>mas você ainda pode convida-lo para fazer parte do CotAí</h2>
          <Form ref={formRefSendMail} onSubmit={handleSubmitSendMail}>
            <Input
              name="email"
              placeholder="ex: joao@empresa.com.br"
              containerStyle={{ marginTop: 20 }}
            />
            <Button loading={loading} type="submit">
              Enviar
            </Button>
            <Button clear onClick={handleToggleSearch}>
              Pesquisar outro fornecedor
            </Button>
          </Form>
        </SearchError>
      )}
    </ModalGlobal>
  );
};

export default SearchProviderModal;
