import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  memo,
} from 'react';
import {
  OptionTypeBase,
  ValueType,
  ActionMeta,
  OptionsType,
} from 'react-select';
import Select, { AsyncProps } from 'react-select/async';

import { useField } from '@unform/core';

import { Container, Title, AsyncSelectContent } from './styles';

export type LoadOptionsHandler = (
  inputValue: string,
  callback?: (options: OptionsType<OptionTypeBase>) => void,
) => Promise<OptionTypeBase[] | void> | void;

export interface AsyncSelectProps extends AsyncProps<OptionTypeBase> {
  name: string;
  title?: string;
  containerStyle?: object;
  isMulti?: boolean;
  getOptionLabel?: (option: OptionTypeBase) => string;
  getOptionValue?: (option: OptionTypeBase) => string;
  loadOptions: LoadOptionsHandler;
  onChange?: (
    value: ValueType<OptionTypeBase>,
    action: ActionMeta<OptionTypeBase>,
  ) => void;
}

export interface AsyncSelectHandler {
  reloadOptions: (handler: LoadOptionsHandler) => Promise<void>;
}

const AsyncSelect: React.ForwardRefRenderFunction<
  AsyncSelectHandler,
  AsyncSelectProps
> = (
  { name, getOptionValue, title, containerStyle, ...rest },
  asyncSelectRef,
) => {
  const selectRef = useRef<Select<OptionTypeBase>>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useImperativeHandle(
    asyncSelectRef,
    () => ({
      reloadOptions: async (handler: LoadOptionsHandler) => {
        if (selectRef.current) {
          const { inputValue } = selectRef.current.state;

          const result = await handler(inputValue);

          if (result && Array.isArray(result)) {
            selectRef.current.setState({
              defaultOptions: result,
              inputValue: '',
            });

            if (result.length)
              // @ts-ignore
              selectRef.current.select.setState({ value: result[0] });
            // @ts-ignore
            else selectRef.current.select.setState({ value: undefined });
          }
        }
      },
    }),
    [],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      setValue(ref, value) {
        // TODO support isMulti

        ref.select.select.setValue(value);
      },
      getValue(ref) {
        if (rest.isMulti) {
          if (!ref.select.state.value) return [];

          if (getOptionValue) return ref.select.state.value.map(getOptionValue);

          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }

        if (!ref.select.state.value) return '';

        if (getOptionValue) return getOptionValue(ref.select.state.value);

        return ref.select.state.value;
      },
    });
  }, [fieldName, getOptionValue, registerField, rest.isMulti]);

  return (
    <Container style={containerStyle}>
      {title && <Title>{title}</Title>}
      <AsyncSelectContent
        ref={selectRef}
        defaultValue={defaultValue}
        getOptionValue={getOptionValue}
        classNamePrefix="react-select"
        defaultOptions
        placeholder="Selecione"
        {...rest}
      />
    </Container>
  );
};

export default memo(forwardRef(AsyncSelect));
