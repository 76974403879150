import React, { useState, useRef, useCallback } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  HeaderTitle,
  BackButton,
  InputTitleEdit,
  InputTitle,
  ButtonGlobal,
  SelectAsync,
  Select,
} from '../../components';
import api from '../../services/api';
import { debounce } from '../../utils/debounceEvent';
import {
  Container,
  Header,
  TopContent,
  TableContent,
  Table,
  DescriptionHeader,
  ButtonCloseModal,
  HeaderModal,
} from './styles';

interface QuoteProps {
  title: string;
  description: string;
  location: string;
  deadline: string;
}

interface Category {
  id: string;
  name: string;
  subCategory: [string];
}

interface Product {
  id: string;
  product: string;
  manufacturer: string;
  category: string;
  amount: string;
}

const QuoteWithoutProposal: React.FC = () => {
  const [modalIsOpen, setOpenModal] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [category] = useState<Category[]>([]);
  const [loadingCategory] = useState(true);

  const [topContent] = useState([
    {
      id: 1,
      title: 'Nova sala TI',
      proposal: '03',
      deadline: '27-08-2020',
      price: '1.587,55',
      status: 'Enviado',
    },
    {
      id: 2,
      title: 'Nova sala TI',
      proposal: '03',
      deadline: '27-08-2020',
      price: '1.587,55',
      status: 'Em análise',
    },
    {
      id: 3,
      title: 'Nova sala TI',
      proposal: '03',
      deadline: '27-08-2020',
      price: '1.587,55',
      status: 'Aguardando',
    },
  ]);

  function openModal() {
    setOpenModal(true);
  }
  function closeModal() {
    setOpenModal(false);
  }

  const handleSubmit = useCallback<SubmitHandler<QuoteProps>>(
    async formData => {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        title: Yup.string().required('Título obrigatório'),
        description: Yup.string().required('Descrição obrigatória'),
        deadline: Yup.string().notRequired(),
        location: Yup.string().notRequired(),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const { title, description, deadline, location } = formData;
      await api.post('/', { title, description, deadline, location });

      toast('Alterações salvas.', {
        type: 'success',
      });
    },
    [],
  );

  const handleSelectCategoryChange = useCallback(async categories => {
    try {
      const response = await api.get(`category/${categories.id}`);

      const { data } = response.data;

      return data;
    } catch (error) {
      toast('Categoria não encontrada', { type: 'error' });

      return [];
    }
  }, []);

  function handleChangeSubCategory(data: any) {
    console.log(data);
  }

  return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            minHeight: '100vh',
            display: 'flex',
            backgroundColor: 'rgba(0, 0, 0, 0.30)',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'relative',
            background: '#fff',
            padding: 15,
            width: 'auto',
            height: 320,
          },
        }}
      >
        <HeaderModal>
          <h1>Adicionar produtos</h1>
          <ButtonCloseModal type="button" onClick={closeModal}>
            <MdClose size={30} />
          </ButtonCloseModal>
        </HeaderModal>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            title="Categoria:"
            isLoading={loadingCategory}
            name="category"
            options={category}
            defaultValue={category[0]}
            getOptionLabel={categories => categories.name}
            getOptionValue={categories => categories.id}
            onChange={handleSelectCategoryChange}
            containerStyle={{ width: 350, paddingRight: 0 }}
          />

          <div style={{ display: 'flex', paddingTop: 20, paddingBottom: 20 }}>
            <SelectAsync
              title="Produto:"
              name="product"
              getOptionLabel={product => product.name}
              getOptionValue={product => product.id}
              // @ts-ignore
              loadOptions={debounce(handleChangeSubCategory, 1500)}
              cacheOptions
              defaultOptions
              containerStyle={{ paddingRight: 10 }}
            />
            <InputTitle
              title="Quantidade:"
              name="amount"
              placeholder="Ex: 02"
              containerStyle={{ width: 80 }}
            />
          </div>

          <ButtonGlobal
            type="button"
            title="Adicionar"
            containerStyle={{ height: 50, width: '100%' }}
          />
        </Form>
      </Modal>
      <Header>
        <HeaderTitle title="Cotação Toner Sala 204" titleColor="#3784F6" />
        <BackButton />
      </Header>
      <DescriptionHeader>
        <h1>
          Observação: Você pode editar os campos e adicionar novos produtos
          enquanto o status da cotação mantiver em aguardo de novas propostas.
        </h1>
      </DescriptionHeader>
      <TopContent>
        <Form ref={formRef} onSubmit={handleSubmit} id="formHeader">
          <div style={{ display: 'flex' }}>
            <InputTitleEdit
              name="title"
              title="Título"
              containerStyle={{ width: 700 }}
            />
            <InputTitle
              disabled
              name="deadline"
              title="Prazo da cotação"
              containerStyle={{ flex: 1 }}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <InputTitleEdit
              name="description"
              title="Descrição"
              containerStyle={{ width: 700 }}
            />
            <InputTitle
              disabled
              name="deadline"
              title="Local de recebimento"
              containerStyle={{ flex: 1 }}
            />
          </div>
        </Form>
      </TopContent>
      <ButtonGlobal
        title="Adicionar produtos"
        type="button"
        containerStyle={{ width: 200, marginLeft: 20 }}
        onClick={openModal}
      />
      <Table>
        <ul style={{ color: '#232242' }}>
          <li style={{ fontWeight: 'bold', color: '#232242' }}>Produto</li>
          <li style={{ fontWeight: 'bold', color: '#232242' }}>Categoria</li>
          <li style={{ fontWeight: 'bold', color: '#232242' }}>Fabricante</li>
          <li style={{ fontWeight: 'bold', color: '#232242' }}>Modelo</li>
          <li style={{ fontWeight: 'bold', color: '#232242' }}>Quantidade</li>
        </ul>
        <TableContent>
          {topContent &&
            topContent.map(repo => (
              <ul key={repo.id}>
                <a href={`/products:${repo.id}`}>
                  <li>{repo.title} </li>
                  <li>{repo.proposal}</li>
                  <li>{repo.deadline}</li>
                  <li>R$ {repo.price}</li>
                  <li>Teste</li>
                </a>
              </ul>
            ))}
        </TableContent>
      </Table>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <ButtonGlobal type="submit" title="Salvar" form="formHeader" />
      </div>
    </Container>
  );
};

export default QuoteWithoutProposal;
