export function formatCnpj(value: string): string {
  let cnpjFormated = value;

  // Coloca ponto entre o segundo e o terceiro dígitos
  cnpjFormated = cnpjFormated.replace(/^(\d{2})(\d)/, '$1.$2');

  // Coloca ponto entre o quinto e o sexto dígitos
  cnpjFormated = cnpjFormated.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

  // Coloca uma barra entre o oitavo e o nono dígitos
  cnpjFormated = cnpjFormated.replace(/\.(\d{3})(\d)/, '.$1/$2');

  // Coloca um hífen depois do bloco de quatro dígitos
  cnpjFormated = cnpjFormated.replace(/(\d{4})(\d)/, '$1-$2');
  return cnpjFormated;
}
