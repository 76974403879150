import React, { HTMLAttributes } from 'react';

import Alert from '../Icons/alert';
import { Container } from './styles';

interface NotFoundProps extends HTMLAttributes<HTMLDivElement> {
  component?: React.ComponentType;
  title?: string;
  description?: string;
  width?: number;
  height?: number;
}

const NotFound: React.FC<NotFoundProps> = ({
  component,
  title = 'Nenhum dado encontrado',
  description,
  width = 270,
  height = 270,
  children,
  ...rest
}) => {
  const Component = component || Alert;

  return (
    <Container {...rest}>
      <Component width={width} height={height} />

      <h1>{title}</h1>
      {description && <h3>{description}</h3>}

      {children}
    </Container>
  );
};

export default NotFound;
