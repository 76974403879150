import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  margin-left: 60px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

export const HeaderTitle = styled.h3`
  color: #3784f6;
  font-size: 14px;
  font-weight: 600;
`;

export const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  span {
    font-size: 11px;
  }
`;

export const Divider = styled.hr`
  border: 1px #f8f9fc solid;
  margin: 8px 0;
`;

export const ProductNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;

export const ProductDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: end;
`;

export const ProductName = styled.h6`
  font-size: 13px;
  font-weight: 600;
  margin-top: 8px;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 13px;
    font-weight: 600;
    margin-top: 8px;
  }

  h3 {
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
  }
`;
