import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const Content = styled.div`
  flex: 1;
  max-width: 1110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  padding: 70px 0;

  h1 {
    font-size: 30px;
    font-weight: 400;
    color: #3784f6;
    text-align: left;
  }

  form {
    flex: 1;
    width: 340px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
  }
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #3784f6;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: none;
    transition: 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: ${shade(0.2, '#3784f6')};
    }

    input {
      display: none;
    }
  }
`;
