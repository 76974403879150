import React from 'react';

import { ProductProposal } from '../../../../@types/product';
import { formatPrice } from '../../../../utils/currency';
import { formatDate } from '../../../../utils/date';
import {
  Container,
  Header,
  HeaderTitle,
  ProductContainer,
  ProductNameContainer,
  ProductPriceContainer,
  ProductDateContainer,
  ProductName,
  Divider,
  NotFoundContainer,
} from './styles';

interface LastProductsProviderProps {
  lastProducts: ProductProposal[];
}

const LastProductsProvider: React.FC<LastProductsProviderProps> = ({
  lastProducts,
}) => {
  return (
    <Container>
      <Header>
        <HeaderTitle>Últimos produtos</HeaderTitle>
      </Header>

      {!lastProducts.length && (
        <NotFoundContainer>
          <h1>Fornecedor novo?</h1>
          <h3>
            Parece que esse fornecedor não respondeu nenhuma cotação lorem ipsum
          </h3>
        </NotFoundContainer>
      )}

      {lastProducts.map((product, index) => (
        <>
          <ProductContainer>
            <ProductNameContainer>
              <span>Produto</span>
              <ProductName>{product.name}</ProductName>
            </ProductNameContainer>

            <ProductPriceContainer>
              <span>Preço</span>
              <ProductName>
                {formatPrice(product.priceCents || '0')}
              </ProductName>
            </ProductPriceContainer>

            <ProductDateContainer>
              <span>Data</span>
              <ProductName>{formatDate(product.updatedAt)}</ProductName>
            </ProductDateContainer>
          </ProductContainer>

          {index !== lastProducts.length - 1 && <Divider />}
        </>
      ))}
    </Container>
  );
};

export default LastProductsProvider;
