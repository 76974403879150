import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  margin: 15px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s;

  h3 {
    padding-top: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #3784f6;
  }
`;
