import styled from 'styled-components';

export const Container = styled.div``;

export const Space = styled.div`
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const Content = styled.div`
  flex: 1;
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  padding: 20px 0;

  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #3784f6;
    text-align: left;
  }

  form {
    flex: 1;
    width: 340px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }
`;

export const HeaderButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CompanyContent = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const Table = styled.div`
  margin-top: 10px;
  flex: 1;
  width: 100%;
  background: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    border-radius: 8px;
    transition: 0.2s;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;

export const TableContent = styled.div`
  min-height: 300px;
  padding-top: 10px;
  overflow: auto;
  border-radius: 8px;
  background: #f1f1f1;
  transition: 0.2s;

  &::-webkit-scrollbar {
    display: auto;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    border: 3px #f1f1f1 solid;

    &:hover {
      border: 3px #4786fa solid;
    }

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;
export const ButtonCloseModal = styled.button`
  background: none;
  border: none;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #3784f6;
    text-align: left;
  }
`;
