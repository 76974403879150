import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  margin: 0;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #3784f6;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-radius: 8px;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#3784f6')};
    }

    h1 {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
