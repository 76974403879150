import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  margin: 0;

  button {
    flex: 1;
    border: none;
    display: flex;
    padding: 7px 25px;
    align-items: center;
    justify-content: space-around;
    background: #3784f6;
    border-radius: 20px;
    color: #fff;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#3784f6')};
    }

    h1 {
      font-size: 16px;
      font-weight: 500;
      padding-left: 10px;
    }
  }
`;
