import React, { useCallback, memo, RefObject } from 'react';
import { toast } from 'react-toastify';

import { FormHandles } from '@unform/core';

import SelectAsync, {
  AsyncSelectProps,
} from '../../../components/Select/Async';
import api from '../../../services/api';
import { formatAddress } from '../../../utils/address';
import { debounce } from '../../../utils/debounceEvent';

type SelectAddressProps = Partial<AsyncSelectProps> & {
  formRef: RefObject<FormHandles>;
};

const SelectAddress: React.FC<SelectAddressProps> = ({ formRef, ...rest }) => {
  const handleChange = useCallback(
    async (inputValue, callback) => {
      try {
        const response = await api.get(`addresses`);

        const { data: addresses } = response;

        if (!addresses.length)
          toast('Nenhum endereço encontrado', { type: 'info' });
        else formRef.current?.setFieldValue('address', addresses[0]);

        callback(addresses);
      } catch (error) {
        toast('Endereço não encontrado', { type: 'error' });

        callback([]);
      }
    },
    [formRef],
  );

  const handleGetOptionLabel = useCallback(address => {
    // const { street, streetNumber, city, neighborhood } = address;

    // const {
    //   name: cityName,
    //   province: { uf },
    // } = city;

    // return `${street}, ${streetNumber}, ${neighborhood}, ${cityName} - ${uf}`;
    return formatAddress(address, {
      complement: true,
      zipcode: true,
      formatZipcode: true,
    });
  }, []);

  return (
    <SelectAsync
      {...rest}
      title="Endereço:"
      name="address"
      getOptionLabel={handleGetOptionLabel}
      getOptionValue={address => address.id}
      loadOptions={debounce(handleChange, 1500)}
      cacheOptions
      containerStyle={{ width: '300px', marginLeft: 0, marginTop: '10px' }}
    />
  );
};

export default memo(SelectAddress);
