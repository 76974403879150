import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { v4 as uuid } from 'uuid';

import { Address } from '../@types/address';
import api from '../services/api';
import { useAuth } from './auth';

interface Company {
  id: string;
  name: string;
  cnpj: string;
  address?: Address;
  createdAt: string;
  updatedAt: string;
}

export type ListenerHandler = (company: Company) => Promise<void> | void;

export interface Listener {
  id: string;
  onChange: ListenerHandler;
}

interface CompanyContextData {
  companies: Company[];
  currentCompany: Company | null;
  loading: boolean;
  loadingMany: boolean;
  switchCompany(company: Company): Promise<Company | null>;
  addCompany(company: Company): void;
  addListener(handler: ListenerHandler): Listener;
  removeListener(id: string): Listener | undefined;
}

const CompanyContext = createContext<CompanyContextData>(
  {} as CompanyContextData,
);

const CompanyProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const { signed } = useAuth();

  const [companies, setCompanies] = useState<Company[]>([]);
  const [currentCompany, setCurrentCompany] = useState<Company | null>(null);
  const [listeners, setListeners] = useState<Listener[]>([]);

  const [loading, setLoading] = useState(false);
  const [loadingMany, setLoadingMany] = useState(true);

  const handleAddListener = useCallback(handler => {
    const listener = { id: uuid(), onChange: handler };

    setListeners(state => {
      const newState = [...state];
      newState.push(listener);

      return newState;
    });

    return listener;
  }, []);

  const handleRemoveListener = useCallback(id => {
    let removedListener;

    setListeners(state => {
      const newState = [...state];

      const listenerIndex = newState.findIndex(listener => listener.id === id);
      if (listenerIndex >= 0) {
        newState.splice(listenerIndex, 1);

        removedListener = state[listenerIndex];
      }
      return newState;
    });

    return removedListener;
  }, []);

  const handleSwitchCompany = useCallback(
    async ({ id }: Company): Promise<Company | null> => {
      try {
        setLoading(true);
        const response = await api.get(`companies/${id}`, {
          headers: { 'Cotai-Company': `${id}` },
        });

        const { data } = response;

        api.defaults.headers['Cotai-Company'] = data.id;

        await Promise.all(
          listeners.map(async listener => listener.onChange(data)),
        );

        setCurrentCompany(data);

        return data;
      } catch (error) {
        toast('Não foi possível carregar suas empresas', { type: 'error' });

        return null;
      } finally {
        setLoading(false);
      }
    },
    [listeners],
  );

  const handleAddCompany = useCallback(async (company: Company) => {
    setCompanies(state => [...state, company]);
  }, []);

  useEffect(() => {
    async function loadCompanies() {
      try {
        const response = await api.get('companies');

        const { data } = response.data;

        if (data) {
          setCompanies(data);

          if (data.length) {
            handleSwitchCompany(data[0]);
          } else {
            toast(
              'Cadastre sua empresa. Para utilizar as funcionalidades do sistema é necessário criar uma empresa ou ser convidado para participar de uma empresa existente',
              { type: 'info' },
            );
            history.push('add-company');
          }
        }
      } catch (error) {
        toast('Não foi possível carregar suas empresas', { type: 'error' });
      } finally {
        setLoadingMany(false);
      }
    }

    if (signed && !currentCompany) setTimeout(() => loadCompanies(), 1000);
  }, [currentCompany, handleSwitchCompany, history, signed]);

  return (
    <CompanyContext.Provider
      value={{
        companies,
        currentCompany,
        loading,
        loadingMany,
        switchCompany: handleSwitchCompany,
        addCompany: handleAddCompany,
        addListener: handleAddListener,
        removeListener: handleRemoveListener,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

function useCompany(): CompanyContextData {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error('useCompany must be used within as CompanyProvider');
  }

  return context;
}

export { CompanyProvider, useCompany };
