import React from 'react';
import { RiInboxUnarchiveLine } from 'react-icons/ri';

import { Container } from './styles';

const NoProductSelected: React.FC = () => {
  return (
    <Container>
      <RiInboxUnarchiveLine size={70} />
      <h1>
        Selecione um produto acima e veja todos os fornecedores que cotaram
      </h1>
    </Container>
  );
};

export default NoProductSelected;
