import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface MenuItemProps {
  title: string;
  path: string;
  icon: React.ComponentType;
  isActive: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  path,
  icon: Icon,
  isActive,
}) => {
  return (
    <Container isActive={isActive}>
      <Link to={path}>
        <Icon />
        <span>{title}</span>
      </Link>
    </Container>
  );
};

export default MenuItem;
