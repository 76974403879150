import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface LinkButtomProps {
  title: string;
  containerStyle?: CSSProperties;
  path: string;
}

const LinkButtom: React.FC<LinkButtomProps> = ({
  title,
  path,
  containerStyle,
  children,
}) => {
  return (
    <Container style={containerStyle}>
      <Link to={path}>
        {children}
        <h1>{title}</h1>
      </Link>
    </Container>
  );
};

export default LinkButtom;
