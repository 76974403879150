import React, { useCallback, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { InputTitle, ButtonGlobal } from '../../../components';
import api from '../../../services/api';
import {
  Container,
  Space,
  Content,
  Table,
  TableContent,
  HeaderButton,
  HeaderModal,
  ButtonCloseModal,
} from './styles';

interface UserProps {
  name: string;
  cnpj: string;
}

const Users: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [company] = useState([
    {
      id: '1',
      name: 'Tiago Abighail Cenoura',
      sector: 'Comercial',
      company: 'Ford Motor Company Brasil Ltda',
      email: 'teste@teste.com.br',
    },
    {
      id: '2',
      name: 'Tiago Abighail Cenoura',
      sector: 'Comercial',
      company: 'Ford Motor Company Brasil Ltda',
      email: 'teste@teste.com.br',
    },
  ]);

  const handleSubmit = useCallback<SubmitHandler<UserProps>>(async formData => {
    formRef.current?.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string().required('Nome obrigatório'),
      cnpj: Yup.string().required('CNPJ obrigatório'),
    });

    await schema.validate(formData, {
      abortEarly: false,
    });

    const { name, cnpj } = formData;
    await api.post('/', { name, cnpj });

    toast('Alterações salvas.', {
      type: 'success',
    });
  }, []);

  const toggleModal = useCallback(() => {
    setModalIsOpen(state => !state);
  }, []);

  return (
    <Container>
      <Space>
        <Content>
          <HeaderButton>
            <ButtonGlobal
              type="button"
              title="Adicionar usuário"
              onClick={toggleModal}
            />
          </HeaderButton>
          <Table>
            <ul style={{ color: '#232242' }}>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>Nome</li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>Email</li>
              <li style={{ fontWeight: 'bold', color: '#232242' }} />
            </ul>
            <TableContent>
              {company &&
                company.map(repo => (
                  <ul key={repo.id}>
                    <li>{repo.name} </li>
                    <li>{repo.email}</li>
                    <li>
                      <button type="button">Excluir</button>
                    </li>
                  </ul>
                ))}
            </TableContent>
          </Table>
        </Content>
      </Space>
      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            minHeight: '100vh',
            display: 'flex',
            backgroundColor: 'rgba(0, 0, 0, 0.30)',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'relative',
            background: '#fff',
            padding: 15,
            width: 'auto',
            // height: 320,
          },
        }}
      >
        <HeaderModal>
          <h1>Adicionar usuário</h1>
          <ButtonCloseModal type="button" onClick={toggleModal}>
            <MdClose size={30} />
          </ButtonCloseModal>
        </HeaderModal>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputTitle
            title="Nome do usuário:"
            name="name"
            placeholder="Insira o nome completo do usuário"
            containerStyle={{ width: 320, marginTop: 40 }}
          />
          <InputTitle
            title="CNPJ:"
            name="CNPJ"
            placeholder="Insira o CNPJ da empresa"
            containerStyle={{ flex: 1, marginLeft: 0, marginTop: 20 }}
          />

          <ButtonGlobal
            type="button"
            title="Adicionar"
            containerStyle={{ flex: 1, width: '100%', marginTop: 20 }}
          />
        </Form>
      </Modal>
    </Container>
  );
};

export default Users;
