import styled, { css } from 'styled-components';

interface ContainerProps {
  isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  background: #f7f7f7;
  padding: 25px;
  border-radius: 8px 8px 0 0;

  ${({ isActive }) =>
    isActive &&
    css`
      background: #fff;
    `}

  & + div {
    margin-left: 10px;
  }

  button {
    flex: 1;
    display: flex;
    text-align: center;
    background: none;
    border: none;
    color: #959595;
    transition: transform 0.3s;

    h1 {
      font-size: 24px;
      font-weight: 500;
    }

    &:hover {
      transform: translateY(-3px);

      color: #3784f6;
      fill: #3784f6;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        color: #3784f6;
        fill: #3784f6;
      `}
  }
`;
