import React, {
  useCallback,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import {
  CategoryProvider,
  Provider,
  SubcategoryProvider,
} from '../../../../@types/provider';
import { Button, Checkbox, ModalGlobal } from '../../../../components';
import api from '../../../../services/api';
import { ButtonContainer } from './styles';

interface ViewMoreCategoriesModalProps {
  onClose: (category?: CategoryProvider) => void;
  isOpen: boolean;
  provider: Provider;
}

export interface ViewMoreCategoryModalHandler {
  setCategory: (category: CategoryProvider) => void;
  setSubcategoriesChecked: (
    subcategoriesChecked: SubcategoryProvider[],
  ) => void;
}

const ViewMoreCategoryModal: React.ForwardRefRenderFunction<
  ViewMoreCategoryModalHandler,
  ViewMoreCategoriesModalProps
> = ({ isOpen, onClose, provider }, ref) => {
  const formRef = useRef<FormHandles>(null);

  const [category, setCategory] = useState<CategoryProvider>();
  const [subcategoriesChecked, setSubcategoriesChecked] = useState<
    SubcategoryProvider[]
  >([]);

  useImperativeHandle(
    ref,
    () => ({ setCategory, setSubcategoriesChecked }),
    [],
  );

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback<SubmitHandler>(
    async formData => {
      try {
        setLoading(true);

        const response = await api.put(
          `/providers/${provider?.id}/categories/${category?.id}`,
          formData,
        );

        onClose(response.data);
      } catch (error) {
        toast('Erro', { type: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [category, onClose, provider],
  );

  return (
    <ModalGlobal
      headerTitle={category ? category.title : 'Categoria'}
      closeModal={onClose}
      isOpen={isOpen}
    >
      {category && (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            subcategories: subcategoriesChecked.map(
              subcategory => subcategory.id,
            ),
          }}
        >
          <Checkbox
            name="subcategories"
            options={category.subcategories.map(subcategory => ({
              id: subcategory.id,
              label: subcategory.title,
              value: subcategory.id,
            }))}
          />
          <ButtonContainer>
            <Button
              containerStyle={{ width: 150, height: 40 }}
              type="submit"
              loading={loading}
            >
              Salvar
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </ModalGlobal>
  );
};

export default forwardRef(ViewMoreCategoryModal);
