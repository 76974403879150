import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.button`
  width: 145px;
  height: 50px;
  background: #3784f6;
  padding: 0 30px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  border: solid 2px #3784f6;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#3784f6')};
    border-color: ${shade(0.2, '#3784f6')};
  }
`;
