import React, { useCallback, useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import {
  CategoryProvider,
  Provider,
  SubcategoryProvider,
} from '../../../../@types/provider';
import { Button, ModalGlobal, SelectAsync } from '../../../../components';
import {
  AsyncSelectHandler,
  LoadOptionsHandler,
} from '../../../../components/Select/Async';
import api from '../../../../services/api';
import { debounce } from '../../../../utils/debounceEvent';

interface AddCategoryModalProps {
  closeModal: () => void;
  isOpen: boolean;
  onSelect: (category: CategoryProvider) => void;
  provider: Provider;
}

interface AddCategoryModalFormData {
  category: string;
  subcategory: string;
}

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({
  isOpen,
  closeModal,
  onSelect,
  provider,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [categoryLoading, setCategoryLoading] = useState(true);

  const [, setSubcategories] = useState<SubcategoryProvider[]>([]);
  const [category, setCategory] = useState<CategoryProvider>();

  const handleSelectInputCategoryChange = useCallback(
    async (search, callback) => {
      try {
        setCategoryLoading(true);

        const response = await api.get('categories', {
          params: { title: search },
        });

        const { data: categories } = response.data;

        if (!categories.length)
          toast('Categoria não encontrada', { type: 'info' });
        else formRef.current?.setFieldValue('category', categories[0]);

        callback(categories);
      } catch (error) {
        toast('Categoria não encontrada', { type: 'error' });

        callback([]);
      } finally {
        setCategoryLoading(false);
      }
    },
    [],
  );

  const handleSelectInputSubcategoryChange = useCallback<LoadOptionsHandler>(
    async (search, callback) => {
      try {
        const categoryId = formRef.current?.getFieldValue('category');

        const response = await api.get('subcategories', {
          params: { title: search, category: categoryId },
        });

        const { data } = response.data;

        if (!data.length)
          toast('Subcategoria não encontrada', { type: 'info' });

        if (callback) callback(data);
        return data;
      } catch (error) {
        toast('Subcategoria não encontrada', { type: 'error' });

        if (callback) callback([]);
        return [];
      }
    },
    [],
  );

  const subcategorySelectRef = useRef<AsyncSelectHandler>(null);

  useEffect(() => {
    if (category && subcategorySelectRef.current)
      subcategorySelectRef.current.reloadOptions(
        handleSelectInputSubcategoryChange,
      );
  }, [category, handleSelectInputSubcategoryChange]);

  const handleSubmit = useCallback<SubmitHandler<AddCategoryModalFormData>>(
    async formData => {
      try {
        const response = await api.post(
          `providers/${provider?.id}/categories`,
          formData,
        );

        onSelect(response.data);

        closeModal();
      } catch (error) {
        const { response } = error;

        if (!response) {
          toast('Erro desconhecido', { type: 'error' });

          return;
        }

        switch (response.status) {
          case 401:
            toast(
              'Você não está autenticado, por favor faça login novamente.',
              {
                type: 'error',
              },
            );
            break;
          case 403:
            toast('Você não está autorizado.', { type: 'warning' });
            break;
          default:
            toast('Erro desconhecido', { type: 'error' });
        }
      }
    },
    [closeModal, onSelect, provider],
  );

  return (
    <ModalGlobal
      headerTitle="Selecione"
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <SelectAsync
          title="Categoria:"
          name="category"
          getOptionLabel={categoryOption => categoryOption.title}
          getOptionValue={categoryOption => categoryOption.id}
          loadOptions={debounce(handleSelectInputCategoryChange)}
          containerStyle={{ width: 400, marginTop: 20 }}
          cacheOptions
          defaultOptions
          // @ts-ignore
          onChange={setCategory}
        />

        {!categoryLoading && (
          <SelectAsync
            ref={subcategorySelectRef}
            title="Subcategoria:"
            name="subcategories"
            getOptionLabel={subcategoryOption => subcategoryOption.title}
            getOptionValue={subcategoryOption => subcategoryOption.id}
            loadOptions={debounce(handleSelectInputSubcategoryChange)}
            // @ts-ignore
            onChange={setSubcategories}
            containerStyle={{ width: 400, marginTop: 20 }}
            cacheOptions
            defaultOptions
            isMulti
          />
        )}
        {!categoryLoading && (
          <Button containerStyle={{ marginTop: 20 }} type="submit">
            Selecionar
          </Button>
        )}
      </Form>
    </ModalGlobal>
  );
};

export default AddCategoryModal;
