import { shade } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 500px;
  background: #fff;
  border-radius: 8px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 500px;
  background: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding-top: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TableContent = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 500px;
  background: #fff;
  border-radius: 8px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
    background: #c9c9c9;
  }

  ::-webkit-scrollbar-thumb {
    background: ${shade(0.1, '#3784f6')};
    border-radius: 2px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
    }
  }
`;

export const TableHeader = styled.ul`
  li {
    color: #c8c8c8;
  }
`;
