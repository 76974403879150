import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Space = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const HeaderButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;

  h3 {
    text-align: left;
    font-size: 13px;
    color: #707070;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

export const Table = styled.div`
  margin-top: 10px;
  flex: 1;
  width: 100%;
  background: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    border-radius: 8px;
    transition: 0.2s;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;

export const TableContent = styled.div`
  min-height: 300px;
  padding-top: 10px;
  overflow: auto;
  border-radius: 8px;
  background: #f1f1f1;
  transition: 0.2s;

  &::-webkit-scrollbar {
    display: auto;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    border: 3px #f1f1f1 solid;

    &:hover {
      background: ${shade(0.03, '#f1f1f1')};
    }

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;

      button {
        background: #ff466b;
        color: #fff;
        padding: 2px 10px;
        border-radius: 4px;
        border: none;
        font-size: 13px;
        margin-right: 15px;
        transition: 0.2s;

        &:hover {
          background: ${shade(0.2, '#ff466b')};
        }
      }
    }
  }
`;
export const ButtonCloseModal = styled.button`
  background: none;
  border: none;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;
