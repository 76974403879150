import * as React from 'react';

function Product(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23 6.066v12.065L11.999 24l-11-5.869V6l11-6L23 6.066zM1.999 17.531l9.5 5.069V12.03l-9.5-4.946v10.447zM22 7.143l-9.501 4.889V22.6L22 17.531V7.143zm-5.52 1.716L6.946 3.895 2.597 6.268l9.404 4.896 4.479-2.305zM8.004 3.318l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z" />
    </svg>
  );
}

export default Product;
