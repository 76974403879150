import React from 'react';
import { Link } from 'react-router-dom';

import { Product } from '../../../@types/product';
import { formatUnit } from '../../../utils/product';
import { Container } from './styles';

interface ProductProps {
  product: Product;
}

const ProductItem: React.FC<ProductProps> = ({ product }) => {
  return (
    <Container>
      <Link to={`/products/${product.id}`}>
        <li>{product.name} </li>
        <li>{product.model} </li>
        <li>{product.manufacturer}</li>
        <li>{formatUnit(product.unitMeasure)}</li>
      </Link>
    </Container>
  );
};

export default ProductItem;
