import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEventHandler,
} from 'react';
import { FaHome } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { FormHandles, Scope, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import cep from 'cep-promise';
import * as Yup from 'yup';

import { Province } from '../../../@types/province';
import AvatarDefaultImg from '../../../assets/avatar.png';
import {
  ButtonGlobal,
  Input,
  Button,
  InputFile,
  Select,
  SelectAsync,
  ModalGlobal,
} from '../../../components';
import { LoadOptionsHandler } from '../../../components/Select/Async';
import { useCompany } from '../../../hooks/company';
import api from '../../../services/api';
import { debounce } from '../../../utils/debounceEvent';
import getValidationErrors from '../../../utils/getValidationErrors';
import { cnpjValidator } from '../../../utils/validators';
import {
  Container,
  Space,
  CompanyContent,
  Table,
  TableContent,
  HeaderButton,
} from './styles';

interface ProfileData {
  name: string;
  cnpj: string;
  address: string;
  location: string;
}

interface CompanyProps {
  name: string;
  cnpj: string;
}

interface AddCompanyFormDataStep1 {
  company: string;
  email: string;
  cnpj: string;
  logo?: File;
}

interface AddCompanyFormDataStep2 {
  address: {
    city: string;
    zipcode: string;
    neighborhood: string;
    street: string;
    streetNumber: string;
    complement?: string;
  };
}

const Companies: React.FC = () => {
  const [modalIsOpen, setOpenModal] = useState(false);
  // const formRef = useRef<FormHandles>(null);
  const formRefStep1 = useRef<FormHandles>(null);
  const formRefStep2 = useRef<FormHandles>(null);
  const [company] = useState([
    {
      id: '1',
      name: 'Ford Motor Company Brasil Ltda',
      cnpj: '03.470.727/0004-73',
      address: 'Rua Jucelino Carvalho, n°4 - 41580-513',
      location: 'Belo Horizonte - MG',
    },
    {
      id: '2',
      name: 'Ford Motor Company Brasil Ltda',
      cnpj: '03.470.727/0004-73',
      address: 'Rua Jucelino Carvalho, n°4 - 41580-513',
      location: 'Belo Horizonte - MG',
    },
  ]);

  const { addCompany } = useCompany();

  const [loading, setLoading] = useState(false);

  const [loadingProvinces, setLoadingProvinces] = useState(true);
  const [provinces, setProvinces] = useState<Province[]>([]);

  const [step, setStep] = useState(0);
  const [formDataStep1, setFormDataStep1] = useState<AddCompanyFormDataStep1>();
  const [formDataStep2, setFormDataStep2] = useState<AddCompanyFormDataStep2>();

  const handleBackForm = useCallback(() => {
    setFormDataStep2(
      formRefStep2.current?.getData() as AddCompanyFormDataStep2,
    );
    setStep(state => state - 1);
  }, []);

  const handleSelectProvincesChange = useCallback(async province => {
    try {
      const response = await api.get(`province/${province.id}/cities`);

      const { data } = response.data;

      formRefStep2.current?.setFieldValue('address.city', data[0]);
      const citySelectRef = formRefStep2.current?.getFieldRef('address.city');
      citySelectRef.state.defaultOptions = data;

      return data;
    } catch (error) {
      toast('Cidade não encontrada', { type: 'error' });

      return [];
    }
  }, []);

  const handleZipcodeChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(async () => {
    const zipcode = formRefStep2.current?.getFieldValue('address.zipcode');

    if (zipcode.length !== 8) return;

    try {
      const zipcodeData = await cep(zipcode);

      formRefStep2.current?.setFieldValue('address.street', zipcodeData.street);
      formRefStep2.current?.setFieldValue(
        'address.neighborhood',
        zipcodeData.neighborhood,
      );

      const provinceIndex = provinces.findIndex(
        p => p.uf === zipcodeData.state,
      );
      const province = provinces[provinceIndex];
      formRefStep2.current?.setFieldValue('address.province', province);

      const response = await api.get(`province/${province.id}/cities`, {
        params: { name: zipcodeData.city },
      });
      const { data: cities } = response.data;
      formRefStep2.current?.setFieldValue('address.city', cities[0]);
      const citySelectRef = formRefStep2.current?.getFieldRef('address.city');
      citySelectRef.state.defaultOptions = cities;
    } catch (error) {
      toast(
        'Falha ao buscar CEP. Por favor, preencha os dados do endereço manualmente',
        { type: 'error' },
      );
    }
  }, [provinces]);

  useEffect(() => {
    async function loadProvinces() {
      try {
        const response = await api.get('provinces');

        setProvinces(response.data);
      } catch (error) {
        toast('Erro ao carregar os estados', { type: 'error' });
      } finally {
        setLoadingProvinces(false);
      }
    }

    loadProvinces();
  }, []);

  const handleInputChangeCity = useCallback<LoadOptionsHandler>(
    async (cityName, callback) => {
      try {
        const province = formRefStep2.current
          ? formRefStep2.current?.getFieldValue('address.province')
          : undefined;

        if (!province && !!provinces.length) {
          const cities = await handleSelectProvincesChange(provinces[0]);

          if (callback) {
            callback(cities);
            return;
          }
        }

        if (!provinces.length && callback) {
          callback([]);
          return;
        }

        const response = await api.get(`province/${province}/cities`, {
          params: { name: cityName },
        });

        const { data } = response.data;

        if (callback) callback(data);
      } catch (error) {
        toast('Cidade não encontrada', { type: 'error' });

        if (callback) callback([]);
      }
    },
    [handleSelectProvincesChange, provinces],
  );

  const handleNextForm = useCallback<SubmitHandler<AddCompanyFormDataStep1>>(
    async formData => {
      try {
        setLoading(true);

        formRefStep1.current?.setErrors({});

        const schema = Yup.object().shape({
          company: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          cnpj: Yup.string().test('is-cnpj', 'CNPJ inválido', cnpjValidator),
          logo: Yup.string(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        setFormDataStep1(formData);
        setStep(state => state + 1);
      } catch (error) {
        setLoading(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefStep1.current?.setErrors(errors);
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const handleSubmit = useCallback<SubmitHandler<AddCompanyFormDataStep2>>(
    async formData => {
      try {
        setLoading(true);

        formRefStep2.current?.setErrors({});

        const schema = Yup.object().shape({
          address: Yup.object().shape({
            city: Yup.string().required('Cidade obrigatória'),
            street: Yup.string().required('Logradouro obrigatório'),
            streetNumber: Yup.string().required('Número obrigatório'),
            zipcode: Yup.string().required('CEP é obrigatório'),
            neighborhood: Yup.string().required('Bairro é obrigatório'),
            complement: Yup.string(),
          }),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const data = {
          ...(formDataStep1 as AddCompanyFormDataStep1),
          ...formData,
        };

        const fd = new FormData();

        if (data.logo) fd.append('logo', data.logo);
        fd.append('name', data.company);
        fd.append('cnpj', data.cnpj);
        fd.append('email', data.email);
        fd.append('address[street]', data.address.street);
        fd.append('address[streetNumber]', data.address.streetNumber);
        fd.append('address[neighborhood]', data.address.neighborhood);
        fd.append('address[zipcode]', data.address.zipcode);
        fd.append('address[city]', data.address.city);
        if (data.address.complement)
          fd.append('address[complement]', data.address.complement);

        const response = await api.post('companies', fd, {
          headers: { 'Content-Type': 'multipart/form-data', type: 'formData' },
        });

        setOpenModal(false);

        toast('Empresa cadastrada! Você já pode fazer novas cotações', {
          type: 'success',
        });

        const { data: responseData } = response;
        addCompany(responseData);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefStep2.current?.setErrors(errors);

          return;
        }

        toast(
          'Erro no cadastro. Ocorreu um erro ao fazer o cadastro, tente novamente.',
          { type: 'error' },
        );
      } finally {
        setLoading(false);
        setOpenModal(false);
      }
    },
    [addCompany, formDataStep1],
  );

  function openModal() {
    setOpenModal(true);
  }
  function closeModal() {
    setOpenModal(false);
  }
  return (
    <Container>
      <Space>
        <HeaderButton>
          <ButtonGlobal
            type="button"
            title="Adicionar empresa"
            onClick={openModal}
          />
        </HeaderButton>
        <CompanyContent>
          <Table>
            <ul style={{ color: '#232242' }}>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>Nome</li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>CNPJ</li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>
                Localização
              </li>
            </ul>
            <TableContent>
              {company &&
                company.map(repo => (
                  <ul key={repo.id}>
                    <li>{repo.name} </li>
                    <li>{repo.cnpj}</li>
                    <li>{repo.location}</li>
                  </ul>
                ))}
            </TableContent>
          </Table>
        </CompanyContent>
      </Space>

      <ModalGlobal
        isOpen={modalIsOpen}
        headerTitle="Insira os dados de sua empresa"
        closeModal={closeModal}
      >
        {step === 0 && (
          <Form
            ref={formRefStep1}
            onSubmit={handleNextForm}
            initialData={formDataStep1}
          >
            <InputFile name="logo" defaultPreview={AvatarDefaultImg} />

            <Input name="company" icon={FaHome} placeholder="Nome da empresa" />

            <Input
              name="cnpj"
              mask="99.999.999/9999-99"
              icon={FiUser}
              placeholder="CNPJ"
            />

            <Input
              name="email"
              icon={FiUser}
              placeholder="Email para contato"
            />

            <Button
              type="submit"
              containerStyle={{
                marginTop: 20,
              }}
            >
              Avançar
            </Button>
          </Form>
        )}

        {step === 1 && (
          <Form
            ref={formRefStep2}
            initialData={formDataStep2}
            onSubmit={handleSubmit}
          >
            <Scope path="address">
              <Input
                name="zipcode"
                mask="99.999-999"
                icon={FiUser}
                placeholder="CEP"
                onChange={debounce(handleZipcodeChange, 1200)}
              />

              {!!provinces.length && (
                <Select
                  isLoading={loadingProvinces}
                  name="province"
                  options={provinces}
                  defaultValue={provinces[0]}
                  getOptionLabel={province => province.name}
                  getOptionValue={province => province.id}
                  onChange={handleSelectProvincesChange}
                  containerStyle={{ paddingBottom: 20 }}
                />
              )}

              {!!provinces.length && (
                <SelectAsync
                  name="city"
                  getOptionLabel={city => city.name}
                  getOptionValue={city => city.id}
                  loadOptions={debounce(handleInputChangeCity, 1500)}
                  containerStyle={{ paddingBottom: 20 }}
                  cacheOptions
                />
              )}

              <Input name="neighborhood" icon={FiUser} placeholder="Bairro" />

              <Input name="street" icon={FiUser} placeholder="Logradouro" />

              <Input name="streetNumber" icon={FiUser} placeholder="Número" />

              <Input
                name="complement"
                icon={FiUser}
                placeholder="Complemento"
              />
            </Scope>

            <div
              style={{
                marginTop: 20,
                display: 'flex',
              }}
            >
              <Button
                onClick={handleBackForm}
                type="button"
                containerStyle={{
                  backgroundColor: '#fff',
                  color: '#3784f6',
                  marginRight: 10,
                  border: 'none',
                }}
              >
                Voltar
              </Button>
              <Button loading={loading} type="submit">
                Salvar
              </Button>
            </div>
          </Form>
        )}
      </ModalGlobal>
    </Container>
  );
};

export default Companies;
