import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Menu from '../../../components/Menu';
import ModalChangeCompany from '../../../components/Menu/ModalChangeCompany';
import { useCompany } from '../../../hooks/company';
import { Container } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const { currentCompany } = useCompany();

  const { location } = useHistory();

  const [openChangeCompany, setOpenChangeCompany] = useState(false);

  const handleToggleChangeCompany = useCallback(() => {
    setOpenChangeCompany(state => !state);
  }, []);

  useEffect(() => {
    if (location) setOpenChangeCompany(false);
  }, [location]);

  return (
    <Container>
      <ModalChangeCompany
        openModal={openChangeCompany}
        onClose={handleToggleChangeCompany}
      />
      <Menu onToggleChangeCompany={handleToggleChangeCompany} />
      {location && location.pathname === '/add-company' && (
        <div style={{ marginLeft: 70 }}>{children}</div>
      )}
      {currentCompany && <div style={{ marginLeft: 70 }}>{children}</div>}
    </Container>
  );
};

export default DefaultLayout;
