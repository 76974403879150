import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const TopContent = styled.div`
  flex: 1;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #959595;
  }
`;

export const TableContent = styled.div`
  width: 100%;
  height: 400px;
  background: #fff;
  border-radius: 18px;
  margin-top: 20px;
  padding-top: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    a {
      text-decoration: none;
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: #000;
      border-radius: 8px;
      transition: 0.1s;
      border: 3px #fff solid;

      &:hover {
        border: 3px #4786fa solid;
      }
    }

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
    }
  }
`;

export const CardInfo = styled.div`
  flex: 1;
  text-align: center;
  background: #fff;
  padding: 15px 20px;
  border-radius: 18px;
  justify-content: space-around;
`;

export const CardDivisor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;
