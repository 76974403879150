import React, { useRef, useEffect, CSSProperties, memo } from 'react';
import { OptionTypeBase, Props as ReactSelectProps } from 'react-select';

import { useField } from '@unform/core';

import { Container, Title, SelectContent } from './styles';

interface SelectProps extends ReactSelectProps<OptionTypeBase> {
  name: string;
  title?: string;
  containerStyle?: CSSProperties;
}

const Select: React.FC<SelectProps> = ({
  name,
  getOptionValue,
  title,
  containerStyle,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      setValue(ref, value) {
        ref.select.setValue(value);
        ref.state.value = value;
      },
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }

        if (!ref.state.value) return '';

        if (getOptionValue) return getOptionValue(ref.state.value);

        return ref.state.value.value;
      },
    });
  }, [fieldName, getOptionValue, registerField, rest.isMulti]);

  return (
    <Container style={containerStyle}>
      {title && <Title>{title}</Title>}
      <SelectContent
        ref={selectRef}
        defaultValue={defaultValue}
        getOptionValue={getOptionValue}
        classNamePrefix="react-select"
        placeholder="Selecione"
        {...rest}
      />
    </Container>
  );
};

export default memo(Select);
