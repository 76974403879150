import { UnitMeasure } from '../@types/product';

export function formatUnit(unit: UnitMeasure): string {
  switch (unit) {
    case UnitMeasure.WEIGHT:
      return 'Peso(Kg)';
    case UnitMeasure.UNIT:
      return 'Unidade';
    case UnitMeasure.METER:
      return 'Metro';
    case UnitMeasure.SQUARE_METER:
      return 'M²';
    case UnitMeasure.CUBIC_METER:
      return 'M³';
    default:
      return unit;
  }
}
