import React from 'react';
import { MdClose } from 'react-icons/md';
import Modal, { Props as ModalPops } from 'react-modal';

import { HeaderModal, ButtonCloseModal, Content } from './styles';

interface ModalGlobalProps extends ModalPops {
  closeModal: () => void;
  isOpen: boolean;
  headerTitle: string;
}

const ModalGlobal: React.FC<ModalGlobalProps> = ({
  closeModal,
  isOpen,
  headerTitle,
  children,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={closeModal}
      style={{
        overlay: {
          minHeight: '100vh',
          display: 'flex',
          backgroundColor: 'rgba(0, 0, 0, 0.30)',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          position: 'relative',
          background: '#fff',
          padding: 15,
          width: 'auto',
          borderRadius: 16,
          overflow: 'visible',
        },
      }}
      {...rest}
    >
      <HeaderModal>
        <h1>{headerTitle} </h1>
        <ButtonCloseModal type="button" onClick={closeModal}>
          <MdClose size={30} />
        </ButtonCloseModal>
      </HeaderModal>

      <Content>{children}</Content>
    </Modal>
  );
};

export default ModalGlobal;
