import AsyncCreatable from 'react-select/async-creatable';

import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 13px;
  color: #707070;
  font-weight: 500;
  padding-bottom: 5px;
`;

export const Button = styled.button`
  border: none;
  background: #3784f6;
  padding: 0 15px;
  border-radius: 6px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#3784f6')};
  }

  h1 {
    font-size: 12px;
    color: #fff;
    font-weight: 400;
  }
`;

export const SelectContent = styled(AsyncCreatable)`
  & .react-select__control {
    background: #f1f1f1;
    height: 53px;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }
  & .react-select__menu {
    padding: 5px 0;
    border-radius: 8px;
    border: none;
  }
`;
