import React, { useMemo, useCallback, useState, useRef } from 'react';
import { IoIosRemoveCircleOutline } from 'react-icons/io';

import {
  CategoryProvider,
  Provider,
  SubcategoryProvider,
} from '../../../../@types/provider';
import api from '../../../../services/api';
import { concatenateEllipsis } from '../../../../utils/string';
import AddCategoryModal from '../AddCategoryModal';
import ViewMoreCategoryModal, {
  ViewMoreCategoryModalHandler,
} from '../ViewMoreCategoriesModal';
import {
  Container,
  Header,
  HeaderTitle,
  HeaderButton,
  CategoryName,
  SubcategoryName,
  CategoryWrapper,
  CategoryContainer,
  SubcategoryContainer,
  ViewMoreButton,
} from './styles';

interface ProviderCategoriesProps {
  categories: CategoryProvider[];
  provider: Provider;
}

const ProviderCategories: React.FC<ProviderCategoriesProps> = ({
  categories: categoriesProps,
  provider,
}) => {
  const [categories, setCategories] = useState<CategoryProvider[]>(
    categoriesProps,
  );
  const [openViewMoreModal, setOpenViewMoreModal] = useState(false);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

  const viewMoreModalRef = useRef<ViewMoreCategoryModalHandler>(null);

  const categoriesProviders = useMemo(() => {
    const categoriesWithSubcategoriesProviders = categories.filter(category => {
      const { subcategories } = category;

      const subcategoryProviderIndex = subcategories.findIndex(
        subcategory => subcategory.provider,
      );

      return subcategoryProviderIndex >= 0;
    });

    const categoriesWithSubcategoriesFilted = categoriesWithSubcategoriesProviders.map(
      category => {
        const { subcategories } = category;

        const subcategoriesProviders = subcategories.filter(
          subcategory => subcategory.provider,
        );

        return { ...category, subcategories: subcategoriesProviders };
      },
    );

    return categoriesWithSubcategoriesFilted;
  }, [categories]);

  const handleToggleOpenAddCategoryModal = useCallback(() => {
    setOpenAddCategoryModal(state => !state);
  }, []);

  const handleToggleViewMoreModal = useCallback(
    (category?: CategoryProvider) => {
      setOpenViewMoreModal(state => !state);

      if (category) {
        setCategories(state => {
          const newState = [...state];

          const findIndex = newState.findIndex(c => c.id === category.id);

          if (findIndex >= 0) newState[findIndex] = category;

          return newState;
        });
      }
    },
    [],
  );

  const handleViewMore = useCallback(
    (category: CategoryProvider) => {
      viewMoreModalRef.current?.setCategory(
        categories.find(({ id }) => id === category.id) || category,
      );

      viewMoreModalRef.current?.setSubcategoriesChecked(category.subcategories);

      handleToggleViewMoreModal();
    },
    [categories, handleToggleViewMoreModal],
  );

  const handleAddCategoryProvider = useCallback(
    (category: CategoryProvider) => {
      setCategories(state => {
        const newState = [...state];

        const findIndex = newState.findIndex(c => c.id === category.id);

        if (findIndex >= 0) newState[findIndex] = category;
        else newState.push(category);

        return newState;
      });
    },
    [],
  );

  const handleRemoveSubcategory = useCallback(
    async (subcategory: SubcategoryProvider) => {
      const response = await api.delete(
        `providers/${provider.id}/subcategories/${subcategory.id}`,
      );

      const { data } = response;

      setCategories(data.categories);
    },
    [provider],
  );

  return (
    <Container>
      <ViewMoreCategoryModal
        ref={viewMoreModalRef}
        isOpen={openViewMoreModal}
        onClose={handleToggleViewMoreModal}
        provider={provider}
      />
      <AddCategoryModal
        isOpen={openAddCategoryModal}
        closeModal={handleToggleOpenAddCategoryModal}
        onSelect={handleAddCategoryProvider}
        provider={provider}
      />
      <Header>
        <HeaderTitle>Categorias</HeaderTitle>
        <div>
          <HeaderButton onClick={handleToggleOpenAddCategoryModal}>
            adicionar
          </HeaderButton>
        </div>
      </Header>

      <CategoryContainer>
        {categoriesProviders.map(category => (
          <CategoryWrapper key={category.id}>
            <CategoryName>
              {concatenateEllipsis(category.title, 25)}
            </CategoryName>

            <SubcategoryContainer>
              {category.subcategories?.map((subcategory, index) => (
                <div key={subcategory.id}>
                  {index < 3 ? (
                    <SubcategoryName>
                      {concatenateEllipsis(subcategory.title, 25)}
                      <button
                        type="button"
                        onClick={() => {
                          handleRemoveSubcategory(subcategory);
                        }}
                      >
                        <IoIosRemoveCircleOutline size={22} color="#fff" />
                      </button>
                    </SubcategoryName>
                  ) : (
                    <>
                      {category.subcategories.length > 4 ? (
                        <>
                          {index === 3 ? (
                            <ViewMoreButton
                              type="button"
                              onClick={() => {
                                handleViewMore(category);
                              }}
                            >
                              ver mais
                            </ViewMoreButton>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <SubcategoryName>
                          {concatenateEllipsis(subcategory.title, 25)}
                          <button
                            type="button"
                            onClick={() => {
                              handleRemoveSubcategory(subcategory);
                            }}
                          >
                            <IoIosRemoveCircleOutline size={22} color="#fff" />
                          </button>
                        </SubcategoryName>
                      )}
                    </>
                  )}
                </div>
              ))}
            </SubcategoryContainer>
          </CategoryWrapper>
        ))}
      </CategoryContainer>
    </Container>
  );
};

export default ProviderCategories;
