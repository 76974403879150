import React, { ButtonHTMLAttributes } from 'react';
import Loader from 'react-loader-spinner';

import { Container } from './styles';

type ButtonGlobalProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  containerStyle?: object;
  type: string;
  title: string;
};

const ButtonGlobal: React.FC<ButtonGlobalProps> = ({
  title,
  containerStyle,
  loading,
  type,
  ...rest
}) => (
  <Container style={containerStyle} type={type} {...rest}>
    {loading ? (
      <Loader type="TailSpin" color="#fff" height={25} width={25} />
    ) : (
      title
    )}
  </Container>
);

export default ButtonGlobal;
