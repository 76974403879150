import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0 0;

  p {
    color: #959595;
    margin-top: 10px;
  }
`;

export const TopContent = styled.div`
  flex: 1;
`;

export const Table = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 3px #fff solid;
  margin: 10px 0;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #000;
    }
  }
`;

export const TableContent = styled.div`
  width: 100%;
  height: 240px;
  background: #fff;
  border-radius: 8px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    a {
      text-decoration: none;
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-radius: 8px;
      transition: 0.1s;
      border: 3px #fff solid;

      &:hover {
        border: 3px #4786fa solid;
      }
    }

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 20px;
    }
  }
`;

export const DescriptionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;

  h1 {
    color: #4786fa;
    font-size: 16px;
    font-weight: 500;
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
    color: #4786fa;
  }
`;

interface StatusProps {
  statusCode?: string;
}

export const Status = styled.li<StatusProps>`
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  p {
    width: 120px;
    height: 20px;
    border-radius: 8px;

    ${({ statusCode }) =>
      statusCode === 'Fechado'
        ? css`
            background: #effcf5;
            color: #2fa06c;
          `
        : statusCode === 'Cancelado'
        ? css`
            background: #feeff2;
            color: #ff0057;
          `
        : statusCode === 'Em Análise'
        ? css`
            background: #fef7ef;
            color: #f6a337;
          `
        : css`
            background: #eeeeee;
            color: #828282;
          `}
  }
`;

export const TableProps = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    padding: 5px 15px;

    border-radius: 20px;
    background: #4786fa;
    color: #fff;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.2, '#4786fa')};
    }
  }
`;
