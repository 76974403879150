import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { validate as isUUID } from 'uuid';
import * as Yup from 'yup';

import { UnitMeasure } from '../../@types/product';
import {
  HeaderTitle,
  BackButton,
  InputTitle,
  ButtonGlobal,
  Select,
  SelectAsync,
  TextArea,
  AsyncSelectHandler,
  LoadOptionsHandler,
} from '../../components';
import { useCompany } from '../../hooks/company';
import api from '../../services/api';
import { debounce } from '../../utils/debounceEvent';
import getValidateErrors from '../../utils/getValidationErrors';
import { formatUnit } from '../../utils/product';
import {
  Container,
  Content,
  Header,
  Space,
  ButtonContainer,
  CancelButton,
} from './styles';

interface AddProductFormData {
  name: string;
  model: string;
  manufacturer: string;
  unitMeasure: string;
  subcategory: string;
  note?: string;
}

interface Category {
  id: string;
  name: string;
  subCategory: [string];
}

const unitsMeasure = Object.values(UnitMeasure).map(unit => ({
  label: formatUnit(unit),
  value: unit,
}));

const AddProduct: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [, setLoading] = useState(false);
  const subcategorySelectRef = useRef<AsyncSelectHandler>(null);

  const { currentCompany } = useCompany();

  const [categoryLoading, setCategoryLoading] = useState(true);

  const [category, setCategory] = useState<Category>();

  const handleSelectInputCategoryChange = useCallback(async (title, cb) => {
    try {
      setCategoryLoading(true);

      const response = await api.get('categories', { params: { title } });

      const { data: categories } = response.data;

      if (!categories.length)
        toast('Categoria não encontrada', { type: 'info' });
      else formRef.current?.setFieldValue('category', categories[0]);

      cb(categories);
    } catch (error) {
      toast('Categoria não encontrada', { type: 'error' });

      cb([]);
    } finally {
      setCategoryLoading(false);
    }
  }, []);

  const handleSelectInputSubcategoryChange = useCallback<LoadOptionsHandler>(
    async (title, callback) => {
      try {
        const categoryId = formRef.current?.getFieldValue('category');

        const response = await api.get('subcategories', {
          params: { title, category: categoryId },
        });

        const { data: subcategories } = response.data;

        if (!subcategories.length)
          toast('Subcategoria não encontrada', { type: 'info' });
        else formRef.current?.setFieldValue('subcategory', subcategories[0]);

        if (callback) callback(subcategories);
        return subcategories;
      } catch (error) {
        toast('Subcategoria não encontrada', { type: 'error' });

        if (callback) callback([]);
        return [];
      }
    },
    [],
  );

  useEffect(() => {
    if (category && subcategorySelectRef.current)
      subcategorySelectRef.current.reloadOptions(
        handleSelectInputSubcategoryChange,
      );
  }, [category, handleSelectInputSubcategoryChange]);

  const handleSubmit = useCallback(async (formData: AddProductFormData) => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        manufacturer: Yup.string().required('Fabricante obrigatório'),
        model: Yup.string().required('Modelo obrigatório'),
        unitMeasure: Yup.string()
          .oneOf(Object.values(UnitMeasure))
          .required('Unidade de medida obrigatória'),
        subcategory: Yup.string()
          .test('uuid', 'Subcategoria inválida', value => isUUID(value))
          .required('Subcategoria obrigatório'),
        note: Yup.string().notRequired(),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const {
        name,
        model,
        manufacturer,
        unitMeasure,
        subcategory,
        note,
      } = formData;

      await api.post('products', {
        name,
        model,
        manufacturer,
        unitMeasure,
        subcategory,
        note: note === '' ? undefined : note,
      });

      toast('Produto salvo. Agora você pode incluí-lo em suas cotações', {
        type: 'success',
      });

      if (formRef.current) formRef.current?.reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidateErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }
      setLoading(false);

      toast('Erro no cadastro', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <Header>
        <HeaderTitle title="Adicionar Produtos" />
        <BackButton />
      </Header>

      {currentCompany && (
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Space>
              <InputTitle
                title="Nome do produto:"
                name="name"
                containerStyle={{ flex: 1 }}
              />
              <InputTitle
                title="Modelo:"
                name="model"
                containerStyle={{ flex: 1 }}
              />
              <InputTitle
                title="Fabricante:"
                name="manufacturer"
                containerStyle={{ flex: 1 }}
              />
            </Space>

            <Space>
              <SelectAsync
                title="Categoria:"
                name="category"
                getOptionLabel={categoryOption => categoryOption.title}
                getOptionValue={categoryOption => categoryOption.id}
                loadOptions={debounce(handleSelectInputCategoryChange)}
                containerStyle={{ flex: 1 }}
                cacheOptions
                defaultOptions
                // @ts-ignore
                onChange={setCategory}
              />

              {!categoryLoading && (
                <SelectAsync
                  ref={subcategorySelectRef}
                  title="Subcategoria:"
                  name="subcategory"
                  getOptionLabel={subcategoryOption => subcategoryOption.title}
                  getOptionValue={subcategoryOption => subcategoryOption.id}
                  loadOptions={debounce(handleSelectInputSubcategoryChange)}
                  // @ts-ignore
                  containerStyle={{ flex: 1, marginLeft: 20, marginRight: 20 }}
                  cacheOptions
                  defaultOptions
                />
              )}
              <Select
                title="Unidade de medida:"
                name="unitMeasure"
                options={unitsMeasure}
                defaultValue={unitsMeasure[0]}
                getOptionLabel={unitMeasure => unitMeasure.label}
                getOptionValue={unitMeasure => unitMeasure.value}
              />
            </Space>

            <TextArea
              title="Observações:"
              name="note"
              containerStyle={{ flex: 1 }}
            />

            <ButtonContainer>
              <CancelButton type="button">
                <h1>Cancelar</h1>
              </CancelButton>
              <ButtonGlobal
                title="Enviar"
                type="submit"
                containerStyle={{ marginLeft: 10 }}
              />
            </ButtonContainer>
          </Form>
        </Content>
      )}
    </Container>
  );
};

export default AddProduct;
