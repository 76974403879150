import React from 'react';

import { SmallQuote } from '../../../@types/quote';
import { formatStatus } from '../../../utils/quote';
import { Status } from './styles';

interface QuoteItemProps {
  quote: SmallQuote;
}

const QuoteItem: React.FC<QuoteItemProps> = ({ quote }) => {
  return (
    <ul>
      <a href={`/quote-detail/${quote.id}`}>
        <li>{quote.title} </li>
        <li>{quote.proposals}</li>
        <li>{quote.deadline}</li>
        <Status statusCode={quote.status}>
          <p>{formatStatus(quote.status)}</p>
        </Status>
      </a>
    </ul>
  );
};

export default QuoteItem;
