import React, { useCallback, useMemo, useRef, useState } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { addDays, differenceInCalendarDays } from 'date-fns';
import * as Yup from 'yup';

import { Subcategory } from '../../@types/category';
import { ProductQuote } from '../../@types/product';
import {
  HeaderTitle,
  BackButton,
  InputTitle,
  ButtonGlobal,
  DatePickerTitle,
  TextArea,
} from '../../components';
import getValidationErrors from '../../utils/getValidationErrors';
import ProductForm from './ProductForm';
import SelectAddress from './SelectAddress';
import { SelectSubcategoryModal } from './SelectSubcategoryModal';
import ShippingTypeModal, {
  ShippingTypeModalHandler,
} from './ShippingTypeModal';
import {
  Container,
  Content,
  Header,
  Space,
  BottomForm,
  ButtonContainer,
} from './styles';

interface AddQuotationFormData {
  title: string;
  description: string;
  note?: string;
  deadline: Date;
  address: string;
  addProduct?: AddProductFormData;
}

interface AddProductFormData {
  category: string;
  product: string;
  quantity: string;
}

const AddQuotation: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const [productQuotes, setProductQuotes] = useState<ProductQuote[]>([]);

  const [subcategoryFilter, setSubcategoryFilter] = useState<Subcategory>();
  const [openSelectSubcategoryModal, setOpenSelectSubcategoryModal] = useState(
    false,
  );

  const [openShippingTypeModal, setOpenShippingTypeModal] = useState(false);
  const shippmentTypeModalRef = useRef<ShippingTypeModalHandler>(null);

  const handleSubmit = useCallback<SubmitHandler<AddQuotationFormData>>(
    async formData => {
      try {
        setLoading(true);
        delete formData.addProduct;
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          note: Yup.string().notRequired(),
          deadline: Yup.string().required('Prazo obrigatório'),
          address: Yup.string().required('Endereço obrigatório'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const products = productQuotes.map(({ id, quantity }) => ({
          product: id,
          quantity,
        }));

        if (shippmentTypeModalRef.current) {
          setOpenShippingTypeModal(true);
          shippmentTypeModalRef.current.setQuoteData({
            ...formData,
            products,
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        }
      } finally {
        setLoading(false);
      }
    },
    [productQuotes],
  );

  const handleCleanSubcategoryFilter = useCallback(() => {
    setSubcategoryFilter(undefined);
  }, []);

  const handleToggleSubcategoryModal = useCallback(() => {
    setOpenSelectSubcategoryModal(state => !state);
  }, []);

  const oneDayLater = useMemo(() => addDays(new Date(), 1), []);

  const dateIsBefore = useCallback(
    (date: Date) => differenceInCalendarDays(date, oneDayLater) > 0,
    [oneDayLater],
  );

  return (
    <Container>
      <SelectSubcategoryModal
        onSelect={setSubcategoryFilter}
        openModal={openSelectSubcategoryModal}
        closeModal={handleToggleSubcategoryModal}
      />
      <ShippingTypeModal
        ref={shippmentTypeModalRef}
        isOpen={openShippingTypeModal}
        closeModal={() => {
          setOpenShippingTypeModal(false);
        }}
      />

      <Header>
        <HeaderTitle title="Criar cotação" />
        <BackButton />
      </Header>

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Space>
            <InputTitle
              title="Título da cotação:"
              name="title"
              containerStyle={{ width: 415 }}
              maxLength={50}
            />
            <InputTitle
              title="Descrição da cotação:"
              name="description"
              containerStyle={{ flex: 1 }}
              maxLength={300}
            />
          </Space>
          <ProductForm
            formRef={formRef}
            productQuotes={productQuotes}
            setProductQuotes={setProductQuotes}
            subcategoryFilter={subcategoryFilter}
            onCleanSubcategoryFilter={handleCleanSubcategoryFilter}
            onSubcategoryFilter={handleToggleSubcategoryModal}
          />
          <BottomForm>
            <TextArea
              title="Observação:"
              name="note"
              containerStyle={{ flex: 1 }}
            />
            <div>
              <DatePickerTitle
                title="Prazo da cotação:"
                name="deadline"
                filterDate={dateIsBefore}
              />
              <SelectAddress formRef={formRef} />
            </div>
          </BottomForm>
          <ButtonContainer>
            <ButtonGlobal
              title="Cancelar"
              type="button"
              containerStyle={{
                backgroundColor: '#FF466B',
                borderColor: '#FF466B',
              }}
            />
            <ButtonGlobal
              title="Enviar"
              type="submit"
              loading={loading}
              containerStyle={{ marginLeft: 10 }}
            />
          </ButtonContainer>
        </Form>
      </Content>
    </Container>
  );
};

export default AddQuotation;
