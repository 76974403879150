import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const ListProviders = styled.div`
  padding-top: 20px;
  width: 100%;

  button {
    margin-top: 10px;
  }
`;

export const SearchError = styled.div`
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  h1 {
    margin-top: 50px;
    font-size: 16px;
    font-weight: 500;
  }

  button {
    margin-top: 10px;
  }
`;
