import styled from 'styled-components';

export const CardDescription = styled.div`
  margin-top: 10px;

  h1 {
    font-size: 16px;
    font-weight: 400;

    span {
      padding-left: 10px;
      font-weight: 600;
    }
  }
`;

export const CardsContainer = styled.div`
  max-width: 1110px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
