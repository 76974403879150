import React from 'react';
import { Switch } from 'react-router-dom';

import Account from '../pages/Account';
import AddCompany from '../pages/AddCompany';
import AddProduct from '../pages/AddProduct';
import AddQuotation from '../pages/AddQuotation';
import Dashboard from '../pages/Dashboard';
import DashboardProduct from '../pages/DashboardProduct';
import DashboardProvider from '../pages/DashboardProvider';
import DashboardQuote from '../pages/DashboardQuote';
import ForgotPassword from '../pages/ForgotPassword';
import ProposalQuote from '../pages/ProposalQuote';
import QuoteDetail from '../pages/QuoteDetail';
import QuoteWithoutProposal from '../pages/QuoteWithoutProposal';
import ResetPassword from '../pages/ResetPassword';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/resetpassword" component={ResetPassword} />

      <Route path="/add-company" component={AddCompany} isPrivate />
      <Route path="/add-quotation" component={AddQuotation} isPrivate />
      <Route path="/add-product" component={AddProduct} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/dashboard-quote" component={DashboardQuote} isPrivate />
      <Route
        path="/dashboard-providers"
        component={DashboardProvider}
        isPrivate
      />
      <Route path="/dashboard-product" component={DashboardProduct} isPrivate />
      <Route path="/proposal-quote" component={ProposalQuote} isPrivate />
      <Route path="/quote-detail/:quoteid+" component={QuoteDetail} isPrivate />
      <Route
        path="/quote-without-proposal"
        component={QuoteWithoutProposal}
        isPrivate
      />
      <Route path="/account" component={Account} isPrivate />
    </Switch>
  );
};

export default Routes;
