import React, { useCallback, useMemo, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Provider } from '../../../../@types/provider';
import { formatCnpj } from '../../../../utils/cnpj';
import { formatDate } from '../../../../utils/date';
import { Container, Detail, ProviderInfoRow } from './styles';

interface ProviderInfoProps {
  provider: Provider;
  onDelete: () => void;
}

const ProviderInfo: React.FC<ProviderInfoProps> = ({ provider, onDelete }) => {
  const [deleteClicked, setDeleteClicked] = useState(false);

  const handleToggleDeleteClicked = useCallback(() => {
    setDeleteClicked(state => !state);

    setTimeout(() => {
      setDeleteClicked(false);
    }, 2000);
  }, []);

  const cnpjFormated = useMemo(() => {
    if (provider) return formatCnpj(provider.cnpj);

    return '';
  }, [provider]);

  const location = useMemo(() => {
    if (provider) {
      return 'Salvador - BA';
    }

    return '';
  }, [provider]);

  return (
    <Container>
      <ProviderInfoRow>
        <Detail justify="flex-start">
          Nome: <span>{provider.name}</span>
        </Detail>
        <Detail justify="center">
          Localização: <span>{location}</span>
        </Detail>
        <Detail justify="flex-end">
          {onDelete ? (
            <button
              type="button"
              onClick={deleteClicked ? onDelete : handleToggleDeleteClicked}
            >
              {deleteClicked ? (
                <>
                  <FiAlertCircle />
                  Clique para confirmar
                </>
              ) : (
                <>Remover fornecedor</>
              )}
            </button>
          ) : (
            <h6>Fornecedor excluído</h6>
          )}
        </Detail>
      </ProviderInfoRow>
      <ProviderInfoRow>
        <Detail justify="flex-start">
          CNPJ: <span>{cnpjFormated}</span>
        </Detail>
        <Detail justify="center">
          Criado em:{' '}
          <span>
            {formatDate(provider.createdAt, "dd 'de' MMMM 'de' yyyy")}
          </span>
        </Detail>
        <Detail justify="flex-end">
          Contato: <span>{provider.email}</span>
        </Detail>
      </ProviderInfoRow>
    </Container>
  );
};

export default ProviderInfo;
