import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0 0;
`;

export const TopContent = styled.div`
  flex: 1;

  form {
    margin: 20px 0;
    background: #fff;
    padding: 20px;

    border-radius: 8px;
  }
`;

export const Table = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 3px #fff solid;
  margin: 20px 0;
  overflow: hidden;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;

export const TableContent = styled.div`
  width: 100%;
  height: 350px;
  background: #fff;
  border-radius: 8px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    a {
      text-decoration: none;
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: #9e9da5;
      border-radius: 8px;
      transition: 0.1s;
      border: 3px #fff solid;

      &:hover {
        border: 3px #4786fa solid;
      }
    }

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
    }
  }
`;

export const DescriptionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;

  h1 {
    font-size: 13px;
    font-weight: 500;
    color: #959595;
  }
`;

export const ButtonCloseModal = styled.button`
  background: none;
  border: none;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  h1 {
    font-size: 18px;
    font-weight: 500;
  }
`;
