import React from 'react';

import { format, parseISO } from 'date-fns';

import { ProductProvider } from '../../../@types/product';
import { Container } from './styles';

interface CardProductProviderProps {
  productProvider: ProductProvider;
}

const CardProductProvider: React.FC<CardProductProviderProps> = ({
  productProvider,
}) => {
  return (
    <Container
      to={`/proposal-quote/${productProvider.proposal.proposal}`}
      key={productProvider.id}
    >
      <h2>{productProvider.provider.name}</h2>
      <p style={{ paddingTop: 5 }}>{productProvider.productProposal.model}</p>

      <p style={{ paddingTop: 5 }}>
        {format(
          parseISO(productProvider.productProposal.deadline),
          'dd/MM/yyyy',
        )}
      </p>

      <h2 style={{ paddingTop: 15 }}>
        <span>R$</span>
        {/* format price */}
        {Number(productProvider.productProposal.priceCents) / 100}
      </h2>
    </Container>
  );
};

export default CardProductProvider;
