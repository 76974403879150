import React from 'react';

function Dashboard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height="25" width="25" viewBox="0 0 512 512" {...props}>
      <path d="M467 17H45C20.187 17 0 37.187 0 62v292c0 24.813 20.187 45 45 45h145.293l-11 66H160c-8.284 0-15 6.716-15 15s6.716 15 15 15h192c8.284 0 15-6.716 15-15s-6.716-15-15-15h-19.293l-11-66H467c24.813 0 45-20.187 45-45V62c0-24.813-20.187-45-45-45zM302.293 465h-92.586l11-66h70.586zM482 354c0 8.271-6.729 15-15 15H45c-8.271 0-15-6.729-15-15V62c0-8.271 6.729-15 15-15h196v17c0 8.284 6.716 15 15 15s15-6.716 15-15V47h196c8.271 0 15 6.729 15 15z" />
      <path d="M208 209c-8.284 0-15 6.716-15 15v49h-34v-97c0-8.284-6.716-15-15-15s-15 6.716-15 15v97H95V128c0-8.284-6.716-15-15-15s-15 6.716-15 15v160c0 8.284 6.716 15 15 15h128c8.284 0 15-6.716 15-15v-64c0-8.284-6.716-15-15-15zM360 121c-47.972 0-87 39.028-87 87 0 23.627 9.472 45.081 24.813 60.775.689.787.656.749 1.411 1.411C314.918 285.527 336.372 295 360 295c47.972 0 87-39.028 87-87s-39.028-87-87-87zm54.983 72H375v-39.983c19.391 5.296 34.687 20.592 39.983 39.983zM345 153.017v48.77l-34.471 34.471c-18.545-32.339-1.316-73.466 34.471-83.241zm-13.258 104.454L366.213 223h48.77c-9.79 35.839-50.946 52.99-83.241 34.471z" />
    </svg>
  );
}

export default Dashboard;
